<template>
    <div class="custom_toggle_container">
        <label class="custom_toggle_switch">
            <input type="checkbox" :checked="stateToggle" v-on:change="toggleChangeFunction">
            <span class="toggle_switch"></span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'CustomToggleComponent',
    data() {
        return {
        }
    },
    props: {
        toggleChangeFunction: {
            type: Function,
            required: true,
        },
        stateToggle: {
            type: Boolean,
            required: true,
        }
    },
};
</script>

<style scoped lang="scss">
    .custom_toggle_container {
        display: flex;
        align-items: center;
        width: 100%;
        .custom_toggle_switch {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 24px;
            input { 
                opacity: 0;
                width: 0;
                height: 0;
            }
            input:checked + .toggle_switch {
                background-color: #2196F3;
            }
            input:checked + .toggle_switch:before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(16px);
            }
            .toggle_switch {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 12px;
                background-color: #EBEFF5;
                -webkit-transition: .4s;
                transition: .4s;
            }
            .toggle_switch:before {
                position: absolute;
                content: "";
                height: 20px;
                width: 20px;
                border-radius: 50%;
                left: 2px;
                bottom: 2px;
                background-color: #FFFFFF;
                -webkit-transition: .4s;
                transition: .4s;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
            }
        }
    }
</style>

