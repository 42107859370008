import assetsData from './assetsData/assetsData';
import authUser from './authorization/authUser.js';
import { createStore } from 'vuex';
import download from './download/download.js';
import filesData from './filesData/filesData.js';
import filterHQData from './filterHQData/filterHQData';
import filtersData from './filtersData/filtersData';
import gamesData from './gamesData/gamesData';
import getFullPreview from './getFullPreview/getFullPreview';
import prepareCollectData from './prepareCollectData/prepareCollectData';
import providersData from './providerData/providerData';
import searchTextData from './searchTextData/searchTextData';
import sectionCollectionData from './sectionCollectionData/sectionCollectionData.js';
import smartFilterData from './smartFilterData/smartFilterData';
import tagsData from './tagsData/tagsData';
import userData from './userData/userData.js';
import userSmartFilterData from './userSmartFilterData/userSmartFilterData';

export default createStore({
  modules: {
    sectionCollectionData,
    userSmartFilterData,
    prepareCollectData,
    smartFilterData,
    searchTextData,
    getFullPreview,
    providersData,
    filterHQData,
    filtersData,
    assetsData,
    filesData,
    gamesData,
    userData,
    download,
    tagsData,
    authUser,
  },
});
