<template>
    <li class="provider_item">
        <CustomCheckboxComponent 
            :checkboxInfo="filterSubtype"
            :checkboxChangeFunction="applyFilter"
            :stateCheckbox="isCheckedProvider"/>
    </li>
</template>

<script>
import CustomCheckboxComponent from '@/components/Others/CustomCheckboxComponent/CustomCheckboxComponent.vue'
import constants from '@/constants/constants';
import createObjectForStore from '@/utils/createObjectForStore/createObjectForStore.js';

export default {
  name: 'ProviderSubtypeComponent',
  data() {
    return {
        checkedFilter: false,
    }
  },
  components: {
    CustomCheckboxComponent,
  },
  props: {
    filterSubtype: {
        type: Object,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
    parentType: {
        type: Object,
        required: true,
    },
  },
  methods: {
    applyFilter() {
        this.checkedFilter = !this.checkedFilter;
        const filter = createObjectForStore(this.parentType, this.filterSubtype);
        if(this.checkedFilter) {
            this.$store.commit('ADD_ACTIVE_PROVIDERS', filter);
            this.$store.commit('UPDATE_DEFAULT_DATA_PROVIDER', this.filterSubtype);
        } else {
            this.$store.commit('REMOVE_ACTIVE_PROVIDERS', filter);
        }

        this.$store.commit('UPDATE_ACTIVE_GAMES_BY_PROVIDER', this.$store.getters.ACTIVE_PROVIDERS);
        this.updateParametersURL(filter, constants.PROVIDERS, this.checkedFilter, constants.GAMES);
        
        this.$store.dispatch('DEBOUNCED_GET_GAME', '').then(() => {
          this.$store.commit('CHECK_DEFAULT_DATA_GAMES', '');
        });
        this.$store.dispatch('DEBOUNCED_GET_DATA', '');
    }
  },
  computed: {
    isCheckedProvider() {
        const provider = this.$store.getters.ACTIVE_PROVIDERS_BY_ID(this.filterSubtype.id);
        this.checkedFilter = !!provider;
        return !!provider;
    },
  },
};
</script>

<style scoped lang="scss">
    .provider_item {
      min-width: 200px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }
</style>