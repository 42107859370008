<template>
    <div class="popup_not_success_enter">
        <div class="popup_icon">
            <img src="@/images/svg/icon_support_info.svg" alt="info">
        </div>
        <div class="popup_message">
            {{ message ? message : "The email or password you entered doesn't match our records. Please double-check and try again"}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupNotSuccesEnter',
    props: {
        message: {
            type: String,
            required: false,
        }
    }
}
</script>
<style>
    .popup_not_success_enter {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 14px 14px;
        background-color: #ffdecc;
        font-size: 14px;
        font-weight: 600;
        color: #d53232;
        .popup_icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            filter: brightness(0) saturate(100%) invert(40%) sepia(46%) saturate(7158%) hue-rotate(343deg) brightness(88%) contrast(89%);
        }
        .popup_message {
            text-align: justify;
        }
    }
</style>