import constants from "@/constants/constants";
import { decodeElementFromURL } from "../en_decodeElementForURL/en_decodeElementForURL";

export default function convertURLtoObject(currentURL) {
    const saveTypeArray = Object.entries(currentURL);

    let asset = [];

    const arrSaveTypes = saveTypeArray.reduce((arr,[type, data]) => {
        if(!data?.length) {
            if(data) {
                arr.push({type: type, content: [ data ]})
            }
            return arr;
        };

        const saveTypeObj = {
            type,
            content: [],
        }

        if(!Array.isArray(data)) {
            saveTypeObj.content.push(decodeElementFromURL(data, type));
        } else {
            saveTypeObj.content = data.map((element) => {
            return decodeElementFromURL(element, type)
            });
        }

        if(saveTypeObj.type === constants.ASSETS) {
            asset.push(saveTypeObj);
        } else {
            arr.push(saveTypeObj);
        }
        return arr;
    }, []);
    return [asset, arrSaveTypes];
}
