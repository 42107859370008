<template>
    <div class="password_block_wrapper">
        <div class="main_password_wrapper">
            <PasswordPanelComponent
              ref="pass_component_first"
              :titleName="'Password'"
              :placeholderName="'At least 8 characters'"
              :isNeedInfoCorrect="false"
              :messageCorrectInfo="''"
              :validatePassword="validatePassword"/>
            <div class="main_password_validation_info">
                <ul class="validation_list">
                    <li class="validation_item" ref="min_eight">
                        <span>&#149;</span> 8 character minimum
                    </li>
                    <li class="validation_item" ref="one_number">
                        <span>&#149;</span> one number
                    </li>
                    <li class="validation_item" ref="one_lower_char">
                        <span>&#149;</span> one lowercase character
                    </li>
                    <li class="validation_item" ref="one_special_char">
                        <span>&#149;</span> one special character
                    </li>
                    <li class="validation_item" ref="one_upper_char">
                        <span>&#149;</span> one uppercase character
                    </li>
                </ul>
            </div>
        </div>
        <PasswordPanelComponent
          ref="pass_component_second"
          :titleName="'Confirm password'"
          :placeholderName="'Repeat your password'"
          :isNeedInfoCorrect="true"
          :messageCorrectInfo="'Passwords do not match. Try again.'"
          :validatePassword="changeConfirmPasswor"/>
    </div>
</template>

<script>
import PasswordPanelComponent from '@/components/Others/InputPanels/PasswordPanelComponent/PasswordPanelComponent.vue';
import constants from '@/constants/constants';

export default {
    name: 'PasswordBlockComponent',
    components: {
        PasswordPanelComponent,
    },
    data () {
        return {
            password: '',
            confirmPassword: '',
            isViewPassword: false,
            validationItem: {
                minEight: constants.VALID_STATUS.DEFAULT,
                oneNumber: constants.VALID_STATUS.DEFAULT,
                oneLowerChar: constants.VALID_STATUS.DEFAULT,
                oneSpecialChar: constants.VALID_STATUS.DEFAULT,
                oneUpperChar: constants.VALID_STATUS.DEFAULT,
            }
        }
    },
    props: {
        isCorrectPasswords: {
            type: Function,
            required: true,
        }
    },
    methods: {
        changeStateValidationItem(element, validValue, oldValue) {
            switch(validValue) {
                case constants.VALID_STATUS.SUCCESS:
                    element.classList.add('validation_success');
                    element.classList.remove('validation_fail');
                    break;
                case constants.VALID_STATUS.FAIL:
                    element.classList.add('validation_fail');
                    element.classList.remove('validation_success');
                    break;
                default:
                    if(oldValue === constants.VALID_STATUS.SUCCESS) {
                        element.classList.remove('validation_success');
                    } else {
                        element.classList.remove('validation_fail');
                    }
                    break;
            }
        },
        validatePassword(inputData) {
            this.password = inputData;
            if(!inputData.length) {
                for(let key in this.validationItem) {
                    this.validationItem[key] = constants.VALID_STATUS.DEFAULT;
                }
            } else {
                this.validationItem.minEight = this.password.length >= constants.MIN_VALID_SYMBOL_PASSWORD ? constants.VALID_STATUS.SUCCESS : constants.VALID_STATUS.FAIL;
                this.validationItem.oneLowerChar = /[a-z]/g.test(this.password) ? constants.VALID_STATUS.SUCCESS : constants.VALID_STATUS.FAIL;
                this.validationItem.oneUpperChar = /[A-Z]/g.test(this.password) ? constants.VALID_STATUS.SUCCESS : constants.VALID_STATUS.FAIL;
                this.validationItem.oneNumber = /[0-9]/g.test(this.password) ? constants.VALID_STATUS.SUCCESS : constants.VALID_STATUS.FAIL;
                this.validationItem.oneSpecialChar = /[\[\]!@#$%^&*=\.\(\)]/g.test(this.password) ? constants.VALID_STATUS.SUCCESS : constants.VALID_STATUS.FAIL;
            }
            this.isMatchPasswords()
            return true;
        },
        changeConfirmPasswor(inputData) {
            this.confirmPassword = inputData;
            return this.isMatchPasswords();
        },
        isMatchPasswords() {
            const isCorrectPass = !Object.values(this.validationItem).filter(status => status !== constants.VALID_STATUS.SUCCESS).length;
            const isCorrect = (this.confirmPassword === this.password) && isCorrectPass;
            this.isCorrectPasswords(this.password, isCorrect);
            return isCorrect;
        },
        isEmptyInputBlock() {
            this.$refs.pass_component_first.isEmptyInput();
            this.$refs.pass_component_second.isEmptyInput();
        },
    },
    watch: {
        'validationItem.minEight'(newV, oldV) {
            this.changeStateValidationItem(this.$refs.min_eight, newV, oldV);
        },
        'validationItem.oneNumber'(newV, oldV) {
            this.changeStateValidationItem(this.$refs.one_number, newV, oldV);
        },
        'validationItem.oneLowerChar'(newV, oldV) {
            this.changeStateValidationItem(this.$refs.one_lower_char, newV, oldV);
        },
        'validationItem.oneUpperChar'(newV, oldV) {
            this.changeStateValidationItem(this.$refs.one_upper_char, newV, oldV);
        },
        'validationItem.oneSpecialChar'(newV, oldV) {
            this.changeStateValidationItem(this.$refs.one_special_char, newV, oldV);
        }
    }
}
</script>
<style scoped lang="scss">
.password_block_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 49px;
    align-items: flex-start;
    justify-content: center;
    .main_password_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .validation_list {
            list-style: none;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px 20px;
            .validation_item {
                width: 170px;
                font-size: 13px;
                text-align: left;
                color: #757575;
            }
            .validation_success {
                color: #00B44E;
                span {
                    color: #00B44E;
                }
            }
            .validation_fail {
                color: #FF2929;
            }
        }
    }
}
</style>