<template>
  <li class="smart_filters_item" @click="activeSmartFilter">
      <div class="smart_filters_icon">
          <img src="@/images/svg/icon_filter.svg" alt="Smart"/>
      </div>
      <div class="smart_filters_name">
        {{ smartFilter.name }}
      </div>
      <slot></slot>
  </li>
</template>

<script>
import constants from '@/constants/constants';
import createObjectForStore from '@/utils/createObjectForStore/createObjectForStore.js';
import { encodeElementForURL } from '@/utils/en_decodeElementForURL/en_decodeElementForURL';

export default {
  name: 'SmartFilterElement',
  props: {
    smartFilter: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      filterURL: [],
      providerURL: [],
      tagURL: [],
      gameURL: [],
      searchTextURL: undefined,
      filterHQ: false,
      isOpenSmartWindow: false,
    }
  },
  methods: {
    updateParametersURL() {
      const currentQuery = { ...this.$route.query };
      currentQuery[constants.HQ_ONLY] = this.filterHQ;
      currentQuery[constants.SEARCH_REQUEST] = this.searchTextURL;
      currentQuery[constants.PROVIDERS] = this.providerURL;
      currentQuery[constants.GAMES] = this.gameURL;
      currentQuery[constants.FILTERS] = this.filterURL;
      currentQuery[constants.TAGS] = this.tagURL;
      this.$router.push({query: currentQuery});
      localStorage.setItem('FILTER_PARAMS_ACTIVE', JSON.stringify(currentQuery));
    },
    activeSmartFilter() {
      this.filterHQ = this.smartFilter.is_hq ? 1 : undefined;
      const filters = this.smartFilter.items;
      const providers = this.smartFilter.providers;
      const games = this.smartFilter.games;
      const tags = this.smartFilter.tags;
      const searchString = this.smartFilter.search_string;
      this.$store.commit('SET_DATA_SEARCH_TEXT', searchString);
      this.$store.commit('SET_DATA_HQ_FILTER', this.filterHQ);
      this.searchTextURL = searchString ? encodeElementForURL(searchString, constants.SEARCH_REQUEST) : undefined;

      try {
        providers.forEach(providerId => {
          const providerChildItem = this.$store.getters.PROVIDER_BY_ID(providerId);
          const providerParentItem = this.$store.getters.DATA_PROVIDERS;
          const providerObject = createObjectForStore(providerParentItem, providerChildItem);
          this.$store.commit('ADD_ACTIVE_PROVIDERS', providerObject);
          this.$store.commit('UPDATE_DEFAULT_DATA_PROVIDER', providerChildItem);
          this.providerURL.push(encodeElementForURL(providerObject, constants.PROVIDERS));
          this.$store.dispatch('GET_DATA_GAMES', '').then(() => {
            this.$store.commit('CHECK_DEFAULT_DATA_GAMES', '');
          });
        })
        games.forEach(gameId => {
          const gameItem = this.$store.getters.GAME_BY_ID(gameId);
          const gameParentItem = this.$store.getters.DATA_GAMES[0];
          const gameObject = createObjectForStore(gameParentItem, gameItem);
          this.$store.commit('ADD_ACTIVE_GAMES', gameObject);
          this.$store.commit('UPDATE_DEFAULT_DATA_GAMES', gameItem);
          this.gameURL.push(encodeElementForURL(gameObject, constants.GAMES));
        })
        tags.forEach(tagId => {
          const tagItem = this.$store.getters.TAG_BY_ID(tagId);
          const tagParentItem = this.$store.getters.DATA_TAGS;
          const tagObject = createObjectForStore(tagParentItem, tagItem);
          this.$store.commit('ADD_ACTIVE_TAG', tagObject);
          this.$store.commit('UPDATE_DEFAULT_DATA_TAG', tagItem);
          this.tagURL.push(encodeElementForURL(tagObject, constants.TAGS));
        })
        filters.forEach(filterValue => {
          const filterChildItem = this.$store.getters.CHILD_FILTER_BY_ID(filterValue);
          const id = filterChildItem.subParentId ? filterChildItem.subParentId : filterChildItem.parentId;
          const filterParentItem = this.$store.getters.PARENT_FILTERS_BY_ID(id);
          const filter = createObjectForStore(filterParentItem, filterChildItem);
          this.$store.commit('ADD_ACTIVE_FILTERS', filter);
          this.filterURL.push(encodeElementForURL(filter, constants.FILTERS))
        })
        this.updateParametersURL();
        this.$store.dispatch('DEBOUNCED_GET_DATA', '');
      } catch(err) {
        console.error(`${err.name}: This smart filter is not working, please contact the administrator`);
      }
    },
  },
}
</script>
