<template>
    <img src="@/images/svg/icon_proportion_square.svg" alt="square" v-if="isSquare()">
    <img src="@/images/svg/icon_proportion_horizontal.svg"
        :class="`${isVerticalPropotion ? 'vertical_image' : 'horizontal_image'}`"
        :alt="`${isVerticalPropotion ? 'vertical' : 'horizontal'}`"
        v-if="isHorizont() || isVertical()">
</template>

<script>
import constants from '@/constants/constants';

export default {
    name: 'PropotionImagesComponent',
    data() {
        return {
            isVerticalPropotion: false,
        }
    },
    props: {
        subFilterName: {
            type: String,
            required: true,
        }
    },
    methods: {
        isSquare() {
            return this.subFilterName.toLowerCase() === constants.PROPORTION_SQUARE;
        },
        isHorizont() {
            return this.subFilterName.toLowerCase() === constants.PROPORTION_HORIZONTAL;
        },
        isVertical() {
            this.isVerticalPropotion = this.subFilterName.toLowerCase() === constants.PROPORTION_VERTICAL;
            return this.isVerticalPropotion;
        },
    },
};
</script>

<style scoped lang="scss">
    .vertical_image {
        transform: rotate(90deg);
    }
</style>
