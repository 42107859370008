import constants from "@/constants/constants";

export default function preserveQuery(router, route) {
    if(route.name !== constants.PATH_NAME.SEARCH) return;
    router.push({
        path: `/${constants.PATH_NAME.SEARCH}`,
        query: {
            ...router.currentRoute.value.query,  // сохраняем текущие query параметры
        }
    });
}
