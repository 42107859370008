import apiRequest from '@/utils/apiRequest/apiRequest.js';
import { cancelDownloadFiles } from '@/utils/downloadFiles/downloadFiles';
import constants from '@/constants/constants';
import { createLinkForRequest } from '@/utils/createLinkForRequest/createLinkForRequest';

const state = {
    dataResponse: [],
    asideImageData: {},
    isFiltersData: {
      providers: false,
      games: false,
      filters: false,
      tags: false,
    },
    isBadRequestFilters: {
      providers: false,
      games: false,
      filters: false,
      tags: false,
    },
    currentPage: constants.DEFAULT_CURRENT_PAGE_NUM,
    lastPage: 0,
    totalDataCount: 0,
    isSoftQuery: false,
    isMaxPage: false,
    loadingTime: false,
    isBadRequest: false,
    timeoutId: null,
  };

const getters = {
    DATA_RESPONSE: state => {
      return state.dataResponse;
    },
    IS_FILE_IN_DATA: (state) => (fileId) => {
      return state.dataResponse.find(({id}) => id === fileId);
    },
    TOTAL_DATA_COUNT: state => {
      return state.totalDataCount;
    },
    IS_SOFT_QUERY: state => {
      return state.isSoftQuery;
    },
    ASIDE_IMAGE_DATA: state => { 
      return state.asideImageData;
    },
    IS_FILTERS_DATA: state => {
      return state.isFiltersData;
    },
    IS_BAD_REQUEST_FILTERS: state => {
      return state.isBadRequestFilters;
    },
    LOADING_TIME: state => {
      return state.loadingTime;
    },
    IS_BAD_REQUEST: state => {
      return state.isBadRequest;
    },
    CURRENT_PAGE: state => {
      return state.currentPage;
    },
    IS_MAX_PAGE: state => {
      return state.currentPage === state.lastPage;
    },
    LAST_PAGE: state => {
      return state.lastPage;
    },
  };

const  mutations = {
    SET_DATA_RESPONSE: (state, payload) => {
      state.dataResponse = payload;
    },
    SET_TOTAL_DATA_COUNT: (state, payload) => {
      state.totalDataCount = payload;
    },
    SET_IS_SOFT_QUERY: (state, payload) => {
      state.isSoftQuery = payload;
    },
    UPDATE_DATA_RESPONSE: (state, payload) => {
      state.dataResponse.push(...payload);
    },
    SET_ASIDE_IMAGE_DATA: (state, payload) => {
      state.asideImageData = payload;
    },
    SET_LOADING_TIME_VALUE: (state, payload) => {
      state.loadingTime = payload;
    },
    SET_IS_BAD_REQUEST: (state, payload) => {
      state.isBadRequest = payload;
    },
    SET_IS_FILTERS_DATA: (state, payload) => {
      state.isFiltersData[payload[0]] = payload[1];
    },
    SET_IS_BAD_REQUEST_FILTERS: (state, payload) => {
      state.isBadRequestFilters[payload[0]] = payload[1];
    },
    INCREASE_CURRENT_PAGE: (state, payload) => {
      state.currentPage = payload ? state.currentPage + 1 : 1;
    },
    SET_LAST_PAGE: (state, payload) => {
      state.lastPage = Math.ceil(payload / constants.SIZE_PAGE);
    },
    RESET_DATA_FILES: (state, payload) => {
      state.dataResponse = [];
      state.asideImageData = {};
      state.isFiltersData = {
        providers: false,
        games: false,
        filters: false,
        tags: false,
      };
      state.isBadRequestFilters = {
        providers: false,
        games: false,
        filters: false,
        tags: false,
      };
      state.currentPage = 1;
      state.lastPage = 0;
      state.totalDataCount = 0;
      state.isMaxPage = false;
      state.loadingTime = false;
      state.isBadRequest = false;
      state.timeoutId = null;
      localStorage.removeItem('FILTER_PARAMS_ACTIVE');
    },
  };

const actions = {
    DEBOUNCE_FUNCTION: function({ state }, { func, delay }) {
      return function (...args) {
        cancelDownloadFiles();
        clearTimeout(state.timeoutId);
        state.timeoutId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    DEBOUNCED_GET_DATA: function({ dispatch, getters, commit }, isUpdate) {
      if(getters.IS_MAX_PAGE && isUpdate) return;

      commit('SET_LOADING_TIME_VALUE', true);

      const debouncedFetch = dispatch('DEBOUNCE_FUNCTION', {
        func: dispatch.bind(this, 'GET_DATA_RESPONSE', isUpdate),
        delay: constants.DEBOUNCE_DELAY,
      });

      debouncedFetch.then(funcRequest => funcRequest());
    },
    GET_DATA_RESPONSE: async ({ getters, commit }, isUpdate) => {
      try {
        const filtersData = {
          filterHQ: getters.DATA_HQ_FILTER,
          activeAsset: getters.ACTIVE_MAIN_ASSET,
          searchText: getters.DATA_SEARCH_TEXT,
          activeProviders: getters.ACTIVE_PROVIDERS,
          activeGames: getters.ACTIVE_GAMES,
          activeFilters: getters.ACTIVE_FILTERS,
          activeTags: getters.ACTIVE_TAGS,
        }

        //данный костыль нужен только пока нет других ассетов. тут происходит отмена каких либо запросов для получения файлов.
        if(filtersData.activeAsset.name !== constants.DEFAULT_ACTIVE_MAIN_ASSET ) {
          commit('SET_DATA_RESPONSE', []);
          commit('SET_LOADING_TIME_VALUE', false);
          return;
        }

        commit('INCREASE_CURRENT_PAGE', isUpdate);

        const response = await apiRequest({
          url: createLinkForRequest(getters.CURRENT_PAGE, filtersData),
          method: 'GET',
          headers: {
            "Cache-Control": "no-cache",
            "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
          },
        }, true);

        const res = response.data.response;

        if(isUpdate) {
          commit('UPDATE_DATA_RESPONSE', res);
        } else {
          commit('SET_LAST_PAGE', response.data.total);
          commit('SET_DATA_RESPONSE', res);
          if(filtersData.filterHQ || filtersData.activeFilters.length || filtersData.activeProviders.length || filtersData.activeTags.length || filtersData.activeGames.length || !!filtersData.searchText) {
            commit('SET_TOTAL_DATA_COUNT', response.data.total);
            commit('SET_IS_SOFT_QUERY', response.data.is_soft_query);
          } else {
            commit('SET_TOTAL_DATA_COUNT', 0);
          }
        }
        commit('SET_LOADING_TIME_VALUE', false);
      } catch (err) {
        commit('SET_LOADING_TIME_VALUE', false);
        commit('SET_IS_BAD_REQUEST', true);
        console.error(`${err.name}: ${err.message}`);
      }
    },
    RESET_ALL_DATA: function({getters, commit}, currentURL) {
      // const {id, name} = getters.DATA_USER;
      // localStorage.setItem(`${name.toLowerCase()}-${id}`, JSON.stringify(currentURL));
      commit('RESET_PRECOLLECT_STORE','');
      commit('RESET_DATA_FILES','');
      commit('RESET_DATA_TOKEN','');
      commit('RESET_DATA_USER','');
      commit('RESET_DATA_ASSET','');
      commit('RESET_DATA_FILTERS','');
      commit('RESET_DATA_HQ_FILTER','');
      commit('RESET_DATA_GAMES','');
      commit('RESET_DATA_PROVIDERS','');
      commit('REMOVE_SEARCH_TEXT','');
      commit('RESET_DATA_SMART_FILTERS','');
      commit('RESET_DATA_TAGS','');
      commit('RESET_DATA_USER_FILTERS','');
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
}
