export default function createObjectForStore(parent, child) {
    let parentId = null;
    const cloneParent = {...parent};

    if(parent.filter_type) {
        parentId = parent.parent_id || parent.id;
    } else if(parent.asset_type_filter_id) {
        parentId = parent.asset_type_filter_id;
        cloneParent.name = 'Asset type';
    } else if(child.provider_id) {
        parentId = child.provider_id;
    }
    const valueId = child.name.toLowerCase().replace(/\s/g,'_') + '-' + child.id;

    const storeObj = {
        name: cloneParent.name?.toLowerCase().replace(/\s/g,'_'),
        value: valueId,
        id: child.id,
        parent_id: parentId,
        activeElementName: child.name,
    };

    return storeObj;
}