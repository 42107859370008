<template>
    <div class="all_tags_popup">
        <div class="tags_search">
            <input type="text" placeholder="Name of tag" v-model="nameTag" class="tags_search_input"/>
        </div>
        <PopupNotFoundInfoComponent :nameElement="filterType.name" v-if="!allTagsView.length"/>
        <ul class="all_tags_component">
            <TagSubtypeComponent v-for="filterSubtype in allTagsView"
                :filterSubtype="filterSubtype"
                :updateParametersURL="updateParametersURL"
                :parentType="filterType" />
        </ul>
    </div>
</template>
<script>
import PopupNotFoundInfoComponent from '@/components/Others/PopupNotFoundInfoComponent/PopupNotFoundInfoComponent.vue';
import TagSubtypeComponent from './../TagSubtypeComponent/TagSubtypeComponent.vue';

export default {
  name: 'TagsPopupComponent',
  data() {
    return {
      nameTag: '',
    }
  },
  components: {
    TagSubtypeComponent,
    PopupNotFoundInfoComponent,
  },
  props: {
    filterType: {
        type: Object,
        required: true,
    },
    allTagsView: {
        type: Array,
        required: true,
    },
    searchTag: {
        type: Function,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
  },
  watch: {
    nameTag(searchWord) {
        this.searchTag(searchWord);
    },
  },
};
</script>
<style scoped lang="scss">
    .all_tags_popup {
      list-style: none;
      padding: 20px 10px;
      margin-right: 10px;
      border-radius: 10px;
      background-color: #ffffff;
      width: auto;
      min-width: 300px;
      min-height: 192px;
      max-height: 500px;
      box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      gap: 20px;
      transition: 1s;
      opacity: 1;
      animation: viewtags .3s ease;
      @keyframes viewtags {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.95;
        }
        100% {
          opacity: 1;
        }
      }
      .tags_search {
        margin-left: 10px;
        display: flex;
        .tags_search_input {
          width: 220px;
          height: 30px;
          background: #EBEFF5;
          border: 1px solid #EBEFF5;
          border-radius: 5px;
          font-size: 14px;
          outline: none;
          padding: 6px 10px;
        }
      }
      .all_tags_component {
        max-width: 480px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        gap: 6px;
        padding-right: 6px;
        scrollbar-gutter: stable;
      }
    }
    .hide-tags {
      animation: hidetags .3s ease;
      @keyframes hidetags {
        0% {
          opacity: 1;
        }
        60% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }
    }
</style>