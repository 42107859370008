<template>
    <div class="popup_success">
      <div class="popup_success_title">
        Done!
      </div>
      <div class="popup_success_body">
        <div class="popup_success_description">
            Registration was successful
        </div>
        <CustomButton
          :nameButton="'Log in'"
          :clickFunction="buttonFunction"
          :styleType="0"/>
      </div>
    </div>
</template>

<script>
import CustomButton from '@/components/Others/CustomButton/CustomButton';

export default {
    name: 'SuccesPopUpComponent',
    components: {
        CustomButton,
    },
    props: {
        buttonFunction: {
            type: Function,
            required: true,
        }
    }
};
</script>

<style scoped lang="scss">
.popup_success {
    position: relative;
    width: 500px;
    max-width: 750px;
    min-height: 430px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px;
    .popup_success_title {
        font-size: 24px;
        font-weight: 700;
        color: #111111;
    }
    .popup_success_body {
        font-size: 15px;
        color: #111111;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .popup_success_description {
            max-width: 440px;
            font-size: 16px;
            color: #757575;
            margin-top: 30px;
            padding-bottom: 25px;
        }
    }
}
</style>
