<template>
    <CommonPopupContainer
      :popUpTitle="'Save to...'"
      :functionClosePopUp="functionClosePopUp">
        <div class="add_popup_panel_wrapper">
            <PopupPanelAddComponent :enableErrorMessage="enableErrorMessage" :disableErrorMessage="disableErrorMessage"/>
            <div class="add_popup_error" v-if="isBadRequest">
                <PopupNotSuccesEnter :message="errorMessage"/>
            </div>
            <div class="popup_panel_btn-save">
                <CustomButton
                  :nameButton="'Save'"
                  :clickFunction="addElementsToCollections"
                  :styleType="0"
                  :iconImage="iconCollect"
                  :disableState="isCollectionListForUpdate"/>
            </div>
        </div>
    </CommonPopupContainer>
</template>

<script>
import CommonPopupContainer from '@/components/Others/CommonPopupContainer/CommonPopupContainer.vue';
import PopupNotSuccesEnter from '@/components/Others/InputPanels/PopupNotSuccesEnter/PopupNotSuccesEnter.vue';
import PopupPanelAddComponent from './PopupPanelAddComponent/PopupPanelAddComponent.vue';
import CustomButton from '@/components/Others/CustomButton/CustomButton.vue';
import iconCollect from '@/images/svg/icon_collect-add.svg';
import constants from '@/constants/constants';

export default {
    name: "AddToCollectionPopupComponent",
    components: {
        CommonPopupContainer,
        PopupNotSuccesEnter,
        PopupPanelAddComponent,
        CustomButton,
    },
    data () {
        return {
            iconCollect,
            isBadRequest: false,
            errorMessage: '',
            isNeedRestoreCollection: false,
            errorCollections: [],
        }
    },
    props: {
        functionClosePopUp: {
            type: Function,
            required: true,
        },
    },
    methods: {
        addElementsToCollections() {
            const collectionsForUpdate = this.$store.getters.COLLECTIONS_LIST_FOR_UPDATE;
            Promise.all(collectionsForUpdate.map((collection) => {
                return this.$store.dispatch('UPDATE_COLLECTION', [collection, false]);
            })).then(([res]) => {
                const {STATUS_CODE} = constants;
                const status = res.status;
                if(status === STATUS_CODE.OK) {
                    this.functionClosePopUp();
                } else if(status === STATUS_CODE.NOT_FOUND || status === STATUS_CODE.BAD_REQUEST || status === STATUS_CODE.CONFLICT) {
                    this.enableErrorMessage(res?.data?.detail);
                    this.isNeedRestoreCollection = true;
                    this.errorCollections.push(res.idErrorCollection);
                } else {
                    this.enableErrorMessage(res.message);
                }
            })
        },
        enableErrorMessage(message) {
            if(message) {
                this.errorMessage = message; 
            }
            this.isBadRequest = true;
        },
        disableErrorMessage() {
            if(this.isBadRequest) {
                this.isBadRequest = false;
            }
        },
    },
    computed: {
        isCollectionListForUpdate() {
            return !this.$store.getters.COLLECTIONS_LIST_FOR_UPDATE.length;
        },
    },
    unmounted() {
        if(this.isNeedRestoreCollection) {
            this.$store.dispatch('GET_USER_COLLECTIONS','');
            this.errorCollections.forEach(id => this.$store.commit('REMOVE_COLLECTION_FROM_LIST_FOR_UPDATE', id));
        }
    },
}
</script>

<style lang="scss">
.add_popup_panel_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 22px;
    .add_popup_error {
        width: 100%;
        padding-right: 12px;
    }
    .popup_panel_btn-save {
        width: 100%;
        height: 40px;
        padding-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
