<template>
    <header class="title-app" id="title_app_element">
        <router-link to="/search" @click="navigateWithPreservedQuery" class="app_logotype">
            <h1>GameArtKit</h1>
        </router-link>
        <div class="user_btns">
            <router-link to="/profile" class="user_btn-acc" v-if="userName">
                <img src="./../images/svg/icon_account.svg" alt="Account"/> {{ defaultUserName }}
            </router-link>
            <button class="user_btn-setting" disabled="true">
                <img src="./../images/svg/icon_settings.svg" alt="Settings user"/> Settings
            </button>
        </div>
    </header>
</template>

<script>
import preserveQuery from '@/utils/preserveQuery/preserveQuery';

export default {
    name: 'TitleApplication',
    data() {
        return {
            defaultUserName: 'Account',
        }
    },
    methods: {
        navigateWithPreservedQuery() {
            preserveQuery(this.$router, this.$route);
        },
    },
    computed: {
        userName() {
            this.defaultUserName = this.$store.getters.DATA_USER.name;
            return this.defaultUserName;
        }
    }
}
</script>

<style scoped lang="scss">
    .title-app {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: 10px;
        margin-right: 10px;
        border-bottom: 1px solid #EBEFF5;
        width: 100%;
        .app_logotype {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            text-decoration: none;
            color: #111111;
            h1 {
                font-size: 20px;
            }
        }
        .user_btns {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            button,
            .user_btn-acc {
                background-color: #fff;
                border: none;
                outline: none;
                text-align: center;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                margin-right: 10px;
                font-size: 15px;
                line-height: 120%;
                gap: 5px;
                img {
                    width: 24px;
                    height: 24px;
                }
            }
            .user_btn-acc {
                text-decoration: none;
                color: #111111;
                &:hover {
                    color: #0085FF;
                    img {
                        filter: brightness(0) saturate(100%) invert(39%) sepia(97%) saturate(2863%) hue-rotate(192deg) brightness(99%) contrast(105%);
                    }
                }
            }
            .user_btn-setting:disabled {
                cursor: default;
                color: rgba(117, 117, 117, 0.8);
                img {
                    filter: brightness(0) saturate(100%) invert(47%) sepia(7%) saturate(17%) hue-rotate(324deg) brightness(96%) contrast(93%);
                    opacity: 60%;
                }
            }
            .router-link-exact-active {
                img {
                    filter: brightness(0) saturate(100%) invert(39%) sepia(97%) saturate(2863%) hue-rotate(192deg) brightness(99%) contrast(105%);
                }
                font-weight: 600;
                color: #0085FF;
                cursor: default;
            }
        }
    }
</style>
