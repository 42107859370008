import axios from "axios";
import constants from "@/constants/constants";
import { getAllUrl } from '@/services/config/env.js';
import store from "@/store/index";

axios.defaults.baseURL = getAllUrl();

export default async function apiRequest(config, isDownload = false) {
    const refreshToken = JSON.parse(localStorage.getItem(constants.LOCAL_STORAGE_NAME_USER));
    if(!refreshToken) return;

    if(isDownload) {
        store.commit('CHANGE_CANCEL_TOKEN_SOURCE', axios.CancelToken.source());
        config.cancelToken = store.getters.CANCEL_TOKEN_SOURCE.token;
    }

    store.commit('SET_IS_BAD_REQUEST', false);
    try {
        const response = await axios(config);
        return response;
    } catch(error) {
        if(error.response && error.response.status === constants.STATUS_CODE.UNAUTHORIZED) {
            await store.dispatch('REFRESH_ACCESS_TOKEN', refreshToken);
            config.headers['Authorization'] = `Bearer ${store.getters.ACCESS_TOKEN}`;
            const retryResponse = await axios(config);
            return retryResponse;
        }
        throw error;
    } finally {
        if(isDownload) {
            store.commit('CHANGE_CANCEL_TOKEN_SOURCE', null);
        }
    }
}
