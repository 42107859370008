<template>
  <div class="create_filter_btn">
    <CustomButton
      :nameButton="'Save custom filter'"
      :clickFunction="openSaveFilterPopup"
      :styleType="0"
      :disableState="!getActiveFilters && !getActiveSearch"/>
  </div>
</template>
<script>
import CustomButton from '@/components/Others/CustomButton/CustomButton.vue';

export default {
  name: 'ButtonSaveToSmartFilter',
  components: {
    CustomButton,
  },
  data() {
    return {
      activeFilters: [],
      activeSearch: '',
      activeMainAssets: null,
    }
  },
  methods: {
    openSaveFilterPopup() {
      this.$store.commit('CHANGE_VIEW_USER_FILTER_POPUP',  {isOpen: true})
    },
  },
  computed: {
    getActiveFilters() {
      const activeProviders = this.$store.getters.ACTIVE_PROVIDERS;
      const activeGames = this.$store.getters.ACTIVE_GAMES;
      const activeCommonFilters = this.$store.getters.ACTIVE_FILTERS;
      const activeTags = this.$store.getters.ACTIVE_TAGS;
      this.activeMainAssets = this.$store.getters.ACTIVE_MAIN_ASSET;
      const activeHQFilter = this.$store.getters.ACTIVE_HQ_FILTER;
      this.activeFilters = [...activeProviders, ...activeGames, ...activeCommonFilters, ...activeTags];

      if(activeHQFilter.isActive) {
        this.activeFilters.push(activeHQFilter)
      }

      return this.activeFilters.length;
    },
    getActiveSearch() {
      this.activeSearch = this.$store.getters.DATA_SEARCH_TEXT;
      return this.activeSearch.length;
    },
  },
}
</script>

<style soped lang="scss">
.create_filter_btn {
  width: 100%;
  padding-top: 10px;
  padding-left: 6px;
  padding-right: 15px;
}
</style>