<template>
    <div class="popup_panel_wrapper">
        <div class="popup_panel_container">
            <div class="popup_panel_default_body">
                <div class="popup_panel_title">
                    {{ popUpTitle }}
                </div>
                <slot></slot>
            </div>
            <div class="popup_panel_btn-close">
                <button @click="functionClosePopUp">
                    <img src="@/images/svg/icon_cross.svg">
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "CommonPopupContainer",
    methods: {
    },
    props: {
        functionClosePopUp: {
            type: Function,
            required: true,
        },
        popUpTitle: {
            type: String,
            required: true,
        },
    }
}
</script>

<style scoped lang="scss">
    .popup_panel_wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        background-color: rgba(17, 17, 17, 0.5);
        &:hover {
            cursor: default;
        }
        .popup_panel_container {
            position: relative;
            left: 24px;
            width: 500px;
            min-height: 164px;
            //max-height: 530px;
            border-radius: 10px;
            padding: 30px 18px 30px 30px;
            background-color: #FFFFFF;
            .popup_panel_default_body {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;
                color: #111111;
                .popup_panel_title {
                    font-size: 20px;
                    font-weight: 700;
                    text-align: start;
                }
            }
            .popup_panel_btn-close {
                position: absolute;
                top: 16px;
                right: 16px;
                img {
                    width: 16px;
                    height: 16px;
                    filter: brightness(0) saturate(100%);
                }
                &:hover {
                    img {
                        filter: brightness(0) saturate(100%) invert(31%) sepia(45%) saturate(3596%) hue-rotate(196deg) brightness(106%) contrast(105%);
                    }
                }
            }
        }
    }
</style>