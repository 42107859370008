<template> 
    <div class="sort_component" v-if="false"> <!-- cкрыт до востребованности -->
        <CommonSortingComponent
          :sortTypeData="radioInfo"/>
        <div class="sort_select_btn"
            @click="openSelectWindow"
            @mouseenter=" isHover=true "
            @mouseleave=" isHover=false ">
            <div class="sort_select_icon">
                <img src="@/images/svg/icon_other_default.svg" alt="">
            </div>
            <RotateArrowIcon
                :isRotateIcon="isOpenSelectWindow"
                :styleIcon="0"
                :isHover="isHover"/>
        </div>
    </div>
</template>

<script>
import CommonSortingComponent from '@/components/Others/CommonSortingComponent/CommonSortingComponent.vue';
import RotateArrowIcon from '@/components/Others/RotateArrowIcon/RotateArrowIcon.vue';

export default {
    name: 'SortComponent',
    components: {
        CommonSortingComponent,
        RotateArrowIcon,
    },
    data () {
        return {
            isOpenSelectWindow: false,
            radioInfo: [{
                name: 'Popular',
                block_name: 'sort_window',
                checked: true,
            },
            {
                name: 'Fresh',
                block_name: 'sort_window',
                checked: false,
            },
            {
                name: 'Rating',
                block_name: 'sort_window',
                checked: false,
            }],
            isHover: false,
        }
    },
    methods: {
        openSelectWindow() {
            this.isOpenSortWindow = false;
            this.isOpenSelectWindow =!this.isOpenSelectWindow;
        },
    },
}
</script>

<style scoped lang="scss">
    .sort_component {
        display: flex;
        gap: 18px;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        min-width: 80px;
        width: 15%;
        .sort_select_btn {
            position: relative;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            font-size: 16px;
            gap: 4px;
            cursor: pointer;
            .sort_select_icon {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
            }
        }
        .sort_select_btn:hover {
            .sort_select_icon {
                filter: brightness(0) saturate(100%) invert(34%) sepia(97%) saturate(2994%) hue-rotate(196deg) brightness(105%) contrast(103%);
            }
        }
    }
</style>