<template>
    <div class="collect_image_item" @click="() => addElementToPreviewFunction(image)" >
        <img class="collect_image_view" :src="image.image_preview_path" alt="preview" v-if="!notFileImage" :onError="imageLoadError">
        <NotFilePreviewComponent :isDraggable="false" v-else/>
        <div class="image_component_btn-remove" @click.stop="() => removeOneElementFunction(index)" >
            <img src="@/images/svg/icon_cross.svg" alt="">
        </div>
    </div>
</template>

<script>
import NotFilePreviewComponent from '@/components/Others/NotFilePreviewComponent/NotFilePreviewComponent.vue';

export default {
    name: "CollectImageComponent",
    components: {
        NotFilePreviewComponent,
    },
    props: {
        image: {
            type: Object,
            requred: true,
        },
        index: {
            type: Number,
            requred: true,
        },
        removeOneElementFunction: {
            type: Function,
            requred: true,
        },
        addElementToPreviewFunction: {
            type: Function,
            requred: true,
        },
    },
    data () {
        return {
            notFileImage: false,
        }
    },
    methods: {
        imageLoadError() {
            this.notFileImage = true;
        }
    },
}
</script>

<style scoped lang="scss">
    .collect_image_item {
        position: relative;
        width: 76px;
        height: 76px;
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        cursor: pointer;
        &:hover::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.25);
        }
        .collect_image_view {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .image_component_btn-remove {
            visibility: hidden;
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 2;
            width: 16px;
            height: 16px;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            img {
                display: block;
                width: 70%;
                height: 70%;
                filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(23%) hue-rotate(164deg) brightness(104%) contrast(106%);
            }
            &:hover {
                img {
                    filter: brightness(0) saturate(100%) invert(84%) sepia(62%) saturate(1565%) hue-rotate(331deg) brightness(101%) contrast(104%);
                }
            }
        }
        &:hover .image_component_btn-remove {
            visibility: visible;
        }
    }
</style>
