<template>
    <div class="smart_filters_component">
        <ul class="smart_filters_list" v-if="canViewSmartFilters">
            <UserSmartFiltersComponent
                v-if="getDataUserSmartFilters"
                :userSmartFilters="userSmartFilters"/>
            <SmartFilterElement v-for="smartFilter in getDataSmartFilters" :smartFilter="smartFilter"/>
        </ul>
    </div>
</template>

<script>
import SmartFilterElement from '../SmartFilterElement/SmartFilterElement.vue';
import UserSmartFiltersComponent from '../UserSmartFiltersComponent/UserSmartFiltersComponent.vue';

export default {
    name: 'SmartFiltersComponent',
    components: {
        UserSmartFiltersComponent,
        SmartFilterElement,
    },
    data () {
        return {
            smartFilters: [],
            userSmartFilters: [],
        }
    },
    computed: {
        getDataSmartFilters() {
            this.smartFilters = this.$store.getters.DATA_SMART_FILTERS;
            return this.smartFilters;
        },
        getDataUserSmartFilters() {
            this.userSmartFilters = this.$store.getters.DATA_USER_FILTERS;
            return Object.values(this.userSmartFilters).length;
        },
        canViewSmartFilters() {
            return this.$store.getters.CAN_VIEW_SMART_FILTERS;
        },
    },
}
</script>

<style lang="scss">
    .smart_filters_component {
        width: 100%;
        padding-right: 4px;
        .smart_filters_list {
            width: 100%;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            .smart_filters_item {
                background-color: #FFFFFF;
                border-radius: 5px;
                border: 1px solid #75757535;
                font-size: 16px;
                //background: transparent;
                min-height: 32px;
                padding: 0px 12px 0px 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                cursor: pointer;
                &:hover {
                    background: #0085FF;
                    border: 1px solid #0085FF;
                    & > .smart_filters_name {
                        color: #ffffff;
                    }
                    & > .smart_filters_icon {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(62deg) brightness(115%) contrast(115%);
                    }
                }
                .smart_filters_icon {
                    img {
                        display: block;
                        width: 16px;
                        height: 16px;
                    }
                }
                .smart_filters_name {
                    white-space: nowrap;
                    max-width: 180px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
</style>
