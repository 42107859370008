<template>
    <CommonPopupContainer
      :popUpTitle="'Create a custom filter'"
      :functionClosePopUp="closeCreateUserFilterPopup">
      <PopupNotSuccesEnter v-if="isBadRequest" :message="errorMessage"/>
        <div class="add_popup_panel_wrapper">
            <PanelChoiceFilters :changeUserFilterName="changeUserFilterName"/>
            <div class="popup_panel_btn-save">
                <CustomButton
                  :nameButton="'Cancel'"
                  :clickFunction="closeCreateUserFilterPopup"
                  :styleType="1"/>
                <CustomButton
                  :nameButton="'Save'"
                  :clickFunction="saveCustomFilter"
                  :styleType="0"
                  :iconImage="iconSave"
                  :disableState="!customFilterName.length"/>
            </div>
        </div>
    </CommonPopupContainer>
</template>

<script>
import CommonPopupContainer from '@/components/Others/CommonPopupContainer/CommonPopupContainer.vue';
import PanelChoiceFilters from './PanelChoiceFilters/PanelChoiceFilters.vue';
import CustomButton from '@/components/Others/CustomButton/CustomButton.vue';
import iconSave from '@/images/svg/icon_save.svg';
import constants from '@/constants/constants';
import PopupNotSuccesEnter from '@/components/Others/InputPanels/PopupNotSuccesEnter/PopupNotSuccesEnter.vue';

export default {
    name: "CreateUserSmartFilterPopup",
    components: {
        CommonPopupContainer,
        PanelChoiceFilters,
        CustomButton,
        PopupNotSuccesEnter,
    },
    data () {
        return {
            iconSave,
            isBtnSaveDisable: true,
            customFilterName: '',
            isBadRequest: false,
            errorMessage: '',
        }
    },
    methods: {
        closeCreateUserFilterPopup() {
            this.$store.commit('CHANGE_VIEW_USER_FILTER_POPUP', {isOpen: false});
        },
        changeUserFilterName(value) {
            this.customFilterName = value;
        },
        saveCustomFilter() {
            this.$store.dispatch('CREATE_CUSTOM_FILTER', this.customFilterName).then((res) => {
                const {STATUS_CODE} = constants;
                const status = res.status;
                if(status === STATUS_CODE.OK) {
                    this.closeCreateUserFilterPopup();
                } else {
                    if(res?.data?.detail) {
                        this.errorMessage = res.data.detail;
                    } else {
                        this.errorMessage = res.message;
                    }
                    this.isBadRequest = true;
                }
            })
        },
    },
}
</script>

<style lang="scss">
.add_popup_panel_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 22px;
    .popup_panel_btn-save {
        width: 100%;
        height: 40px;
        padding-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
}
</style>
