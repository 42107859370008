<template>
    <div class="not_found_info">
        <h3>{{ nameElement }} not found</h3>
        <p>Change your query</p>
    </div>
</template>

<script>

export default {
  name: 'PopupNotFoundInfoComponent',
  props: {
    nameElement: {
        type: String,
        required: true,
    },
  },
};
</script>

<style scoped lang="scss">
    .not_found_info {
        width: 100%;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        background-color: rgba(255, 205, 41, 0.2);
        padding: 18px 21px;
        border-radius: 5px;
        p {
            font-size: 14px;
        }
    }
</style>