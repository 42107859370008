<template>
    <div class="collect_images_nav">
        <button class="collect_images_btn collect_images_btn-clear" @click="clickToClear">
            <img src="@/images/svg/icon_clear.svg" alt="">
        </button>
        <div class="collect_images_btns">
            <button class="collect_images_btn collect_images_btn-add" @click="openAddToCollectPopup">
                <img src="@/images/svg/icon_collect-add.svg" alt="">
            </button>
            <button class="collect_images_btn collect_images_btn-load" @click="downloadCollectImages">
                <div class="btn_load_title" v-if="!isLoading">
                    <img src="@/images/svg/icon_download.svg" alt=""> zip
                </div>
                <div class="btn_load-start" v-if="isLoading" @click.stop="cancelDownloadFiles">
                    <LoaderComponent/>
                </div>
            </button>
        </div>
        <ClearAllPrecollectPopup v-if="isOpenPopUp" :clearAllCollect="clearAllCollect" :closePoUp="closePoUp"/>
    </div>
</template>

<script>
import ClearAllPrecollectPopup from '@/components/PopUpComponents/ClearAllPrecollectPopup/ClearAllPrecollectPopup.vue';
import { downloadFile, downloadFiles, cancelDownloadFiles } from '@/utils/downloadFiles/downloadFiles.js';
import LoaderComponent from '@/components/Others/LoaderComponent.vue';
import constants from '@/constants/constants';

export default {
    name: "CollectNavigationButtons",
    data() {
        return {
            isOpenPopUp: false,
            isLoading: false,
            cancelDownloadFiles,
        }
    },
    components: {
        ClearAllPrecollectPopup,
        LoaderComponent,
    },
    methods: {
        clickToClear() {
            const arrCollect = this.$store.getters.DATA_PRECOLLECT;
            if(!arrCollect.length) return;
            if(arrCollect.length > constants.IS_VIEW_POPUP_CLEAR_FILES_NUMBER) {
                this.isOpenPopUp = true;
            } else {
                this.clearAllCollect();
            }

        },
        clearAllCollect() {
            this.isOpenPopUp = false;
            this.$store.commit('CLEAR_ALL_DATA_PRECOLLECT', [])
        },
        closePoUp() {
            this.isOpenPopUp = false;
        },
        downloadCollectImages() {
            if(this.isLoading) return;

            const arrCollect = this.$store.getters.DATA_PRECOLLECT;
            this.isLoading = true;

            if(arrCollect.length > 1) {
                downloadFiles(arrCollect).then(() => {
                    this.isLoading = false;
                });
            } else if (arrCollect.length) {
                const { id } = arrCollect[0];
                downloadFile(id).then(() => {
                    this.isLoading = false;
                });
            };
        },
        openAddToCollectPopup() {
            this.$store.commit('CHANGE_VIEW_COLLECTION_POPUP',  {isOpen: true, addOnlyOneElement: false})
        },
    }
}
</script>

<style lang="scss">
    .collect_images_nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .collect_images_btn {
            height: 50px;
            min-width: 50px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 10px;
            background-color: #fff;
            font-weight: 600;
            font-size: 15px;
            padding: 0 20px;
            cursor: pointer;
            text-decoration: none;
        }
        .collect_images_btn-clear:hover {
            img {
                filter: brightness(0) saturate(100%) invert(37%) sepia(38%) saturate(6479%) hue-rotate(343deg) brightness(98%) contrast(106%);
            }
        }
        .collect_images_btns {
            display: flex;
            gap: 10px;
            .collect_images_btn:hover {
                color: #7cb2ff;
                img {
                    filter: brightness(0) saturate(100%) invert(86%) sepia(35%) saturate(6545%) hue-rotate(187deg) brightness(100%) contrast(104%);
                }
            }
            .collect_images_btn-load {
                position: relative;
                width: 92px;
                height: 50px;
                display: inline-flex;
                .btn_load_title {
                    display: inline-flex;
                    gap: 5px;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    img {
                        display: block;
                    }
                }
                .btn_load-start {
                    position: absolute;
                    border-radius: 10px;
                    width: 100%;
                    height: 100%;
                    .loader_container {
                        .lds-spinner {
                            max-width: 10px;
                            height: 10px;
                        }
                        .lds-spinner div {
                            transform-origin: 4px 4px;
                        }
                        .lds-spinner div:after {
                            top: 1.2px;
                            left: 9.8px;
                            width: 12.4px;
                            height: 3px;
                            border-radius: 10%;
                        }
                    }
                    &:hover {
                        filter: brightness(0) saturate(100%) invert(37%) sepia(38%) saturate(6479%) hue-rotate(343deg) brightness(98%) contrast(106%);
                    }
                }
            }
        }
    }
</style>