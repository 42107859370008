<template>
  <div class="button_get_all" @click="getAllFunction">
    <div class="button_get_all_title">All {{ nameGetElements.toLowerCase() }}</div>
    <RotateArrowIcon :styleIcon="1"/>
  </div>
</template>
<script>
import RotateArrowIcon from '../RotateArrowIcon/RotateArrowIcon.vue';

export default {
  name: 'GetAllButtonComponent',
    components: {
      RotateArrowIcon,
    },
  data() {
    return {}
  },
  props: {
    getAllFunction: {
      type: Function,
      required: true,
    },
    nameGetElements: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
  .button_get_all {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-radius: 5px;
    font-weight: 600;
    border: 1px solid rgba(117, 117, 117, 0.5);
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      border: 1px solid rgba(117, 117, 117);
    }
  }
</style>