<template>
  <img class="not_file_preview" src="@/images/svg/preview_not_file.svg" :draggable="isDraggable">
</template>
<script>

export default {
  name: "NotFilePreviewComponent",
  props: {
    isDraggable: {
      type: Boolean,
      requred: true,
    },
  },
}
</script>

<style scoped lang="scss">
  .not_file_preview {
    max-width: 100%;
    max-height: 100%;
  }
</style>
