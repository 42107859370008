<template>
    <div class="filter_notification" @mouseover="viewInformation" @mouseout="hideInformation">
      <img src="@/images/svg/icon_support_info.svg" alt="">
      <div class="filter_notification_description" ref="notification" v-show="isViewInformation">
        <div class="filter_notification_info">
          {{ filterDescription }}
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'FilterNotificationComponent',
    data() {
        return {
            isViewInformation: false,
        }
    },
    props: {
        filterDescription: {
            type: String,
            required: true,
        },
    },
    methods: {
        viewInformation(e) {
            this.isViewInformation = true;
            this.$refs.notification.style.top = e.clientY - 25 + 'px';
        },
        hideInformation() {
            this.isViewInformation = false;
        }
  }
}
</script>

<style scoped lang="scss">
    .filter_notification {
        .filter_notification_description {
            position: fixed;
            z-index: 2;
            top: 0px;
            .filter_notification_info {
                position: absolute;
                top: 0px;
                left: 16px;
                border: 1px solid #EBEFF5;
                border-radius: 10px;
                padding: 20px;
                background: #EBEFF5;
                color: #757575;
                box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
                min-width: 200px;
                min-height: 80px;
                text-align: left;
            }
            .filter_notification_info::after, .filter_notification_info::before {
                content: '';
                position: absolute;
                background: #EBEFF5;
                left: -7px; bottom: 60%;
                width: 14px; height: 14px;
                box-shadow: 2px 0 0 #757575;
                z-index: -1;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
               }
            .filter_notification_info::before {
                z-index: 2;
                box-shadow: none;
            }
        }
    }
    .filter_notification:hover img{
        filter: brightness(0) saturate(100%) invert(77%) sepia(89%) saturate(616%) hue-rotate(331deg) brightness(103%) contrast(108%);
    }
</style>