<template>
  <div class="component_icon_arrow">
    <img src="@/images/svg/icon_arrow_down.svg" 
      :class="`${!isRotateIcon ? typeIcon[styleIcon][0] : typeIcon[styleIcon][1]} ${isHover ? 'hover_icon' : ''}`">
  </div>
</template>

<script>

export default {
  name: "RotateArrowIcon",
  data() {
    return {
      typeIcon: {
        0: ['icon_default', 'icon_up'],
        1: ['icon_vertical', 'icon_rotate'],
        2: ['icon_def_white', 'icon_def_white icon_up'],
      },
    }
  },
  props: {
    isRotateIcon: {
      type: Boolean,
      required: false,
    },
    styleIcon: {
      type: Number,
      required: true,
    },
    isHover: {
      type: Boolean,
      required: false,
    },
  },
}
</script>

<style lang="scss">
  .component_icon_arrow {
    display: flex;
    justify-content: center;
    align-content: center;
    img {
      display: block;
      width: 17px;
      opacity: 0.4;
    }
    .icon_default,
    .icon_def_white {
      transform: rotate(-90deg);
    }
    .icon_def_white {
      opacity: 1;
    }
    .icon_vertical {
      opacity: 1;
      transform: rotate(-180deg);
    }
    .icon_up {
      transform: rotate(90deg);
    }
    .hover_icon {
      opacity: 1;
      filter: brightness(0) saturate(100%) invert(86%) sepia(35%) saturate(6545%) hue-rotate(187deg) brightness(100%) contrast(104%);
    }
    .icon_def_white.hover_icon {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7464%) hue-rotate(121deg) brightness(95%) contrast(101%);
    }
    .icon_def_white.icon_up {
      opacity: 1;
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2410%) hue-rotate(344deg) brightness(114%) contrast(104%);
    }
  }
</style>
