<template>
    <div class="bad_request_container">
        <img src="@/images/png/bad_request.png" alt="">
    </div>
</template>

<script>

export default {
  name: 'BadRequesComponent',
};
</script>

<style scoped lang="scss">
    .bad_request_container {
        width: 80%;
        height: 80%;
        font-size: 32px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        img {
            width: 70%;
        }
    }
</style>