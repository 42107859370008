import apiRequest from '@/utils/apiRequest/apiRequest';
import constants from '@/constants/constants';

const state = {
  previewData: {
    id: null,
    link: null,
  },
  timeoutId: null,
};

const getters = {
  IMAGE_LINK: state => {
    return state.previewData;
  },
};

const mutations = {
  SET_IMAGE_LINK: (state, payload) => {
    state.previewData = payload;
  },
  CLEAR_IMAGE_LINK: (state, payload) => {
    state.previewData = {
      id: null,
      link: null,
    };
    state.timeoutId = null;
  },
};

const actions = {
  DEBOUNCE_FULL_IMAGE_FUNCTION: function({ state }, { func, delay }) {
    return function (...args) {
      return new Promise((resolve) => {
        clearTimeout(state.timeoutId);
        state.timeoutId = setTimeout(() => {
          resolve(func.apply(this, args));
        }, delay);
      })
    };
  },
  DEBOUNCED_GET_FULL_IMAGE: async function({ dispatch, getters, commit }, idElement) {
    if(!idElement) return;
    const debouncedFetch = await dispatch('DEBOUNCE_FULL_IMAGE_FUNCTION', {
      func: dispatch.bind(this, 'GET_FULL_IMAGE', idElement),
      delay: constants.DEBOUNCE_DELAY,
    });
    const response = await debouncedFetch();
    return response;
  },
  GET_FULL_IMAGE: async ({getters, commit}, idElement) => {
    try {
      const response = await apiRequest({
        url: `/file_storage/full_image_url?file_id=${idElement}`,
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      const res = response.data;
      commit('SET_IMAGE_LINK', { id: idElement, link: res });
      return response;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
      return err.response;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}