<template>
    <div class="panel_form_name">
      <div class="panel_form_name-title">
        Name
      </div>
      <div class="panel_form_name-input">
        <input type="text" placeholder="Your name" v-model="nameText" @change="validateName(nameText)" ref="name_input">
      </div>
      <div class="panel_form_name-info">
        <span class="panel_form_company-error" v-if="isNotCorrect">
            The name cannot be empty
        </span>
      </div>
    </div>
</template>

<script>
export default {
    name: 'NameInputComponent',
    data () {
        return {
            nameText: '',
            isNotCorrect: false,
        }
    },
    props: {
        changeNameParameters: {
            type: Function,
            required: true,
        },
    },
    methods: {
        validateName(nameText) {
            const nameInput = this.$refs.name_input;
            if(!this.nameText.trim()) {
                nameInput.classList.add('input_not_valid');
            } else {
                nameInput.classList.remove('input_not_valid');
            }
            this.isNotCorrect = !this.nameText.trim();
            this.changeNameParameters(this.nameText, this.isNotCorrect);
            return this.isNotCorrect;
        },
        isEmptyInput() {
            const nameInput = this.$refs.name_input;
            if(!this.nameText.trim()) {
                nameInput.classList.add('input_not_valid');
            }
        },
    },
};
</script>

<style scoped lang="scss">
.panel_form_name {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10px;
    .panel_form_name-input {
        position: relative;
        width: 100%;
        margin-top: 10px;
        input {
            width: 100%;
            height: 40px;
            background-color: #EBEFF5;
            border: 1px solid #EBEFF5;
            border-radius: 10px;
            border-left: 1px solid #75757520;
            padding-right: 36px;
            padding-left: 10px;
            font-size: 16px;
            transition: all .3s ease-out;
            outline: none;
            &:hover {
                border: 1px solid #0085FF;
            }
            &::placeholder {
                font-size: 16px;
            }
        }
        .input_not_valid {
            border: 1px solid #FF2929;
            background-color: #FFF3F3;
            &:hover {
                border: 1px solid #FF2929;
            }
        }
    }
    .panel_form_name-info {
        height: 17px;
        .panel_form_company-error {
            font-size: 13px;
            color: #FF2929;
        }
    }
}
</style>