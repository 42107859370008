<template>
    <CommonPopupContainer
      :popUpTitle="popUpTitle"
      :functionClosePopUp="functionClosePopUp">
      <div class="popup_activate_wapper">
        <div class="popup_panel_description" v-if="popUpDescription">
            {{ popUpDescription }}
        </div>
        <slot></slot>
        <div class="popup_panel_btns">
            <div class="popup_panel_btn">
                <CustomButton
                  :nameButton="popUpButtonCancel"
                  :clickFunction="functionClosePopUp"
                  :styleType="1"/>
            </div>
            <div class="popup_panel_btn">
                <CustomButton
                  :nameButton="popUpButtonAccept"
                  :clickFunction="functionAction"
                  :styleType="styleTypeButton"
                  :iconImage="iconImage"
                  :disableState="disableStateActionBtn"/>
            </div>
        </div>
      </div>
    </CommonPopupContainer>
</template>

<script>
import CommonPopupContainer from '@/components/Others/CommonPopupContainer/CommonPopupContainer.vue';
import CustomButton from '@/components/Others/CustomButton/CustomButton.vue';

export default {
    name: "PopupContainerActivate",
    components: {
        CommonPopupContainer,
        CustomButton,
    },
    methods: {
    },
    props: {
        functionClosePopUp: {
            type: Function,
            required: true,
        },
        functionAction: {
            type: Function,
            required: true,
        },
        popUpTitle: {
            type: String,
            required: true,
        },
        popUpDescription: {
            type: String,
            required: false,
        },
        popUpButtonCancel: {
            type: String,
            required: true,
        },
        popUpButtonAccept: {
            type: String,
            required: true,
        },
        styleTypeButton: {
            type: Number,
            required: true,
        },
        iconImage: {
            type: String,
            required: false,
        },
        disableStateActionBtn: {
            type: Boolean,
            required: false,
        },
    },
}
</script>

<style scoped lang="scss">
        .popup_activate_wapper {
            width: 100%;
            height: 100%;
            padding-right: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            .popup_panel_description {
                color: #757575;
                font-size: 16px;
            }
            .popup_panel_btns {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                padding-top: 10px;
                .popup_panel_btn {
                    width: 214px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                }
            }
        }
</style>
