import constants, { ICON_PATH } from '@/constants/constants';

import apiRequest from '@/utils/apiRequest/apiRequest';
import convertArrayToObject from '@/utils/arrayToObject/arrayToObject';
import { restoreActiveElements } from '@/utils/restoreActiveElements/restoreActiveElements';

const state = {
  dataGames: {
    name: 'Games',    //требуется исправить на сервере
    description: 'Information about games',
    icon_path: ICON_PATH[constants.GAMES],
    fileList: [],
    games_number: null,
    isGame: true,
  },
  allDataGames: [],
  activeGames: [],
  allGamesStore: {},
  timeoutIdGame: null,
};

const getters = {
  DATA_GAMES: state => {
    return state.dataGames;
  },
  ALL_DATA_GAMES: state => {
    return state.allDataGames;
  },
  GET_STORE_GAMES: state => {
    return state.allGamesStore;
  },
  GAME_BY_ID: (state) => (gameId) => {
    return state.allGamesStore[gameId];
  },
  ACTIVE_GAMES: state => {
    return state.activeGames;
  },
  ACTIVE_GAMES_BY_ID: (state)=> (id) => {
    return state.activeGames.find(game => game.id === id);
  },
};

const mutations = {
  SET_DATA_GAMES: (state, payload) => {
    state.dataGames.fileList = payload.game_name;
    state.dataGames.games_number = payload.game_name_number;
  },
  SET_ALL_DATA_GAMES: (state, payload) => {
    state.allDataGames = payload.game_name;
    state.dataGames.games_number = payload.game_name_number;
  },
  SET_ALL_GAMES_STORE: (state, payload) => {
    state.allGamesStore = payload;
  },
  ADD_ACTIVE_GAMES: (state, payload) => {
    state.activeGames.push(payload);
  },
  ADD_ACTIVE_GAMES_RESTORE: (state, payload) => {
    restoreActiveElements(state.allGamesStore, state.dataGames.fileList, state.activeGames, payload);
  },
  UPDATE_DEFAULT_DATA_GAMES: (state, payload) => {
    const isElement = state.dataGames.fileList.find(({id}) => id === payload.id);
    if(!isElement) {
      state.dataGames.fileList.push(payload);
    }
  },
  CHECK_DEFAULT_DATA_GAMES: (state, payload) => {
    state.activeGames.forEach(gameEl => {
      const isElement = state.dataGames.fileList.find(({id}) => id === gameEl.id);
      if(!isElement) {
        state.dataGames.fileList.push(state.allGamesStore[gameEl.id]);
      }
    });
  },
  REMOVE_ACTIVE_GAMES: (state, payload) => {
    state.activeGames = state.activeGames.filter(game => {
        return  game.value !== payload.value;
    });
  },
  UPDATE_ACTIVE_GAMES_BY_PROVIDER: (state, payload) => {
    const providersId = payload.map(provider => provider.id);
    if(!payload.length) return;

    state.activeGames = state.activeGames.filter(({parent_id}) => providersId.includes(parent_id));
  },
  REMOVE_ALL_ACTIVE_GAMES: (state, payload) => {
    state.activeGames = [];
  },
  RESET_DATA_GAMES: (state, payload) => {
    state.dataGames.fileList = [];
    state.dataGames.games_number = null;
    state.allDataGames = [];
    state.allGamesStore = {};
    state.activeGames = [];
    state.timeoutIdGame = null;
  },
};

const actions = {
  DEBOUNCE_FUNCTION_GAME: function({ state }, { func, delay }) {
    return function (...args) {
      return new Promise((resolve) => {
        clearTimeout(state.timeoutIdGame);
        state.timeoutIdGame = setTimeout(() => {
          resolve(func.apply(this, args));
        }, delay);
      })
    };
  },
  DEBOUNCED_GET_GAME: async function({ dispatch, getters, commit }, payload) {
    const debouncedFetch = await dispatch('DEBOUNCE_FUNCTION_GAME', {
      func: dispatch.bind(this, 'GET_DATA_GAMES', payload),
      delay: constants.DEBOUNCE_DELAY,
    });
    const response = await debouncedFetch();
    return response;
  },
  GET_DATA_GAMES: async ({getters, commit}, payload) => {
    const arrayActiveProviders = getters.ACTIVE_PROVIDERS;
    let providerId = '';
    for (let i = 0; i <= arrayActiveProviders.length-1; i++) {
      providerId += `provider_id=${arrayActiveProviders[i].id}&`;
    }
    try {
      if(!getters.DATA_GAMES.fileList.length) {
        commit('SET_IS_FILTERS_DATA', [constants.GAMES, true]);
      }
      const response = await apiRequest({
        url: `/provider/game?${providerId}get_all=false`,
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      commit('SET_IS_FILTERS_DATA', [constants.GAMES, false]);

      let res = response.data;

      // if(!arrayActiveProviders.length) {
      //   res.game_name = res.game_name.slice(0,6);
      // }
      commit('SET_DATA_GAMES', res);
    } catch (err) {
      commit('SET_IS_FILTERS_DATA', [constants.GAMES, false]);
      commit('SET_IS_BAD_REQUEST_FILTERS', [constants.GAMES, true]);
      console.error(`${err.name}: ${err.message}`);
    }
  },
  GET_ALL_DATA_GAMES: async ({getters, commit}, payload) => {
    const arrayActiveProviders = getters.ACTIVE_PROVIDERS;
    let providerId = '';

    for (let i = 0; i <= arrayActiveProviders.length-1; i++) {
      providerId += `provider_id=${arrayActiveProviders[i].id}&`;
    }

    try {
      const response = await apiRequest({
        url: `/provider/game?${providerId}get_all=true`,
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      const res = response.data;
      commit('SET_ALL_DATA_GAMES', res);

      if(!Object.keys(getters.GET_STORE_GAMES).length) {
        commit('SET_ALL_GAMES_STORE', convertArrayToObject(res.game_name));
      }

      return res.game_name;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
}