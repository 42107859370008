<template>
    <main class="start_home_page">
      <div class="start_home_page_name_service">
        GameArtKit
      </div>
      <div class="start_home_page_main">
        <slot></slot>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: 'StartPageCompontent',
  };
  </script>
  
  <style scoped lang="scss">
      .start_home_page {
          position: fixed;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 100;
          background-image: url("@/images/jpg/auth_background.jpg");
          background-size: cover;
          .start_home_page_name_service {
            font-size: 20px;
            font-weight: 700;
            position: absolute;
            top: 40px;
            left: 40px;
          }
          .start_home_page_main {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
      }
  
  </style>
  