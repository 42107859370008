<template>
    <div class="header_panel">
        <AssetTypePanelComponent/>
        <SearchPanel/>
    </div>

</template>

<script>
import AssetTypePanelComponent from './AssetTypePanelComponent/AssetTypePanelComponent.vue';
import SearchPanel from './SearchPanelComponent/SearchPanelComponent.vue';

export default {
    name: 'HeaderPanelComponent',
    data() {
        return {

        }
    },
    components: {
        AssetTypePanelComponent,
        SearchPanel,
    },

}

</script>

<style scoped lang="scss">
    .header_panel {
        display: flex;
        align-items: center;
        width: 100%;
    }
</style>