<template>
    <div class="filter_popup_wrapper">
        <div class="filter_popup_search">
            <input type="text" :placeholder="`Name of ${filterTypeName.toLowerCase()}`" v-model="searchValue" class="filter_popup_search_input"/>
        </div>
        <PopupNotFoundInfoComponent :nameElement="filterTypeName" v-if="!dataElements.length"/>
        <div class="filter_popup_body">
          <ul class="filter_popup_list" ref="list_elements">
            <slot></slot>
          </ul>
        </div>
    </div>
</template>
<script>
import PopupNotFoundInfoComponent from '@/components/Others/PopupNotFoundInfoComponent/PopupNotFoundInfoComponent.vue';
import getCountColumns from '@/utils/getCountColumns/getCountColumns';

export default {
  name: 'FilterPopupComponent',
  data() {
    return {
      searchValue: '',
    }
  },
  components: {
    PopupNotFoundInfoComponent,
  },
  props: {
    filterTypeName: {
        type: String,
        required: true,
    },
    dataElements: {
        type: Array,
        required: true,
    },
    findElement: {
        type: Function,
        required: true,
    },
  },
  watch: {
    searchValue(searchWord) {
        this.findElement(searchWord);
    },
    dataElements(newValue) {
      this.$refs.list_elements.style.columnCount = getCountColumns(newValue.length);
    }
  },
  mounted() {
    this.$refs.list_elements.style.columnCount = getCountColumns(this.dataElements.length);

    const scrollContainer = document.querySelector('.filter_popup_body');

    scrollContainer.addEventListener('wheel', (e) => {
      e.preventDefault();
      scrollContainer.scrollLeft += e.deltaY;
    });
  }
};
</script>
<style lang="scss">
    .filter_popup_wrapper {
      list-style: none;
      padding: 20px 10px;
      margin-right: 10px;
      border-radius: 10px;
      background-color: #ffffff;
      width: auto;
      min-width: 410px;
      min-height: 192px;
      box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      gap: 20px;
      transition: 1s;
      opacity: 1;
      animation: viewpopup .3s ease;
      @keyframes viewpopup {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.95;
        }
        100% {
          opacity: 1;
        }
      }
      .filter_popup_search {
        margin-left: 10px;
        display: flex;
        .filter_popup_search_input {
          width: 220px;
          height: 30px;
          background: #EBEFF5;
          border: 1px solid #EBEFF5;
          border-radius: 5px;
          font-size: 14px;
          outline: none;
          padding: 6px 10px;
        }
      }
      .filter_popup_body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 73vw;
        //max-width: 1122px;
        overflow-x: auto;
        scrollbar-gutter: stable;
        .filter_popup_list {
          column-count: 3;
          column-rule: solid 1px #EBEFF5;
          gap: 30px; /* Расстояние между элементами */
          padding: 10px;
          line-height: 1.429;
          white-space: normal;
          display: inline-block;
          li {
            min-width: 325px;
            width: 354px;
            position: relative;
            padding-right: 0;
            padding-bottom: 6px;
            //margin-left: 10px;
            align-items: flex-start;
          }
        }
      }
    }
</style>