<template>
    <div class="loader_container" >
        <div class="loader loader--style6" title="5">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="100px" height="50px" viewBox="0 0 24 30" style="enable-background:new 0 0 60 60;" xml:space="preserve">
              <rect x="0" y="50" width="6" height="50"  rx="4" fill="#333">
                <animate attributeName="height" attributeType="XML"
                  values="5;21;5"
                  begin="0s" dur="0.8s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML"
                  values="13;5;13"
                  fill="remove"
                  begin="0s" dur="0.8s" repeatCount="indefinite" />
              </rect>
              <rect x="10" y="50" width="6" height="50" rx="4" fill="#333">
                <animate attributeName="height" attributeType="XML"
                  values="5;21;5"
                  begin="0.15s" dur="0.8s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML"
                  values="13;5;13"
                  begin="0.15s" dur="0.8s" repeatCount="indefinite" />
              </rect>
              <rect x="20" y="50" width="6" height="50" rx="4" fill="#333">
                <animate attributeName="height" attributeType="XML"
                  values="5;21;5"
                  begin="0.3s" dur="0.8s" repeatCount="indefinite" />
                <animate attributeName="y" attributeType="XML"
                  values="13;5;13"
                  begin="0.3s" dur="0.8s" repeatCount="indefinite" />
              </rect>
            </svg>
          </div>
    </div>
</template>

<script>

export default {
  name: 'LoaderComponent',
};
</script>

<style scoped lang="scss">
    .loader_container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: view 0.4s ease-in forwards;
      opacity: 0;
      @keyframes view {
        from {
          opacity: 0;
        } 
        to {
          opacity: 100%;
        }
      }
      .loader{
        width: 100%;
        //height: 100px;
        display: inline-block;
      }
      svg path,
      svg rect{
        fill: #dedede;
      }
    }
</style>
