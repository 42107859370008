<template>
  <div class="filters_panel_type">
    <div class="filters_panel_type-hq">
        <div class="filters_panel_type-enable" v-if="isCheckedHQFilter"></div>
        <div class="hq_type-title">
          <div>
            <img src="@/images/svg/icon_hq-filter.svg" alt="">
          </div>
          <div>
            HQ only
          </div>
        </div>
        <div class="hq_type-body">
          <CustomToggleComponent
            :toggleChangeFunction="applyFilter"
            :stateToggle="checkedFilter"
          />
        </div>
    </div>
  </div>
</template>
<script>
import CustomToggleComponent from '@/components/Others/CustomToggleComponent/CustomToggleComponent.vue';

export default {
  name: 'FilterHQComponent',
  data() {
    return {
      checkedFilter: false,
    }
  },
  components: {
    CustomToggleComponent,
  },
  methods: {
    applyFilter() {
        this.checkedFilter = !this.checkedFilter;
        this.$store.commit('SET_DATA_HQ_FILTER', this.checkedFilter);
        this.updateParametersURL(this.checkedFilter);
        this.$store.dispatch('DEBOUNCED_GET_DATA', '');
    },
    updateParametersURL(hqState) {
        const currentQuery = { ...this.$route.query };
        currentQuery.hq_only = hqState ? '1' : undefined;
        this.$router.push({query: currentQuery});
        localStorage.setItem('FILTER_PARAMS_ACTIVE', JSON.stringify(currentQuery));
    },
  },
  computed: {
    isCheckedHQFilter() {
        this.checkedFilter = this.$store.getters.DATA_HQ_FILTER;
        return this.checkedFilter;
    },
  },
};
</script>
<style scoped lang="scss">
  .filters_panel_type {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .filters_panel_type-hq {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      width: 100%;
      cursor: pointer;
      &:hover .hq_type-title {
          color: #0085FF;
      }
      .filters_panel_type-enable {
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #FFCC29;
        left: 1px;
      }
      .hq_type-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        transition: all .3s ease-out;
        padding-left: 9px;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
</style>