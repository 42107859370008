<template>
    <div class="not_found_container">
        <h3>Nothing was found for your request</h3>
        <p>Change your query or choose something from popular images</p>
    </div>
</template>

<script>

export default {
  name: 'NotFoundComponent',
};
</script>

<style scoped lang="scss">
    .not_found_container {
        width: 100%;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: flex-start;
        gap: 6px;
        background-color: rgba(255, 205, 41, 0.2);
        padding: 18px 21px;
        border-radius: 5px;
        p {
            font-size: 14px;
            text-align: left;
        }
    }
</style>