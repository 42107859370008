import apiRequest from '@/utils/apiRequest/apiRequest.js';

const state = {
  userInformation: {},
};

const getters = {
  DATA_USER: state => {
    return state.userInformation;
  },
};

const mutations = {
  SET_DATA_USER: (state, payload) => {
    state.userInformation = payload;
  },
  RESET_DATA_USER: (state, payload) => {
    state.userInformation = {};
  },
};

const actions = {
  /* GET_DATA_USER: async ({getters, commit}, payload) => {
    try {
      const response = await apiRequest({
        url: `/user/?email=${payload}`,
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      const res = response.data;
      commit('SET_DATA_USER', res[0]);
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
    }
  }, */
  CHANGE_DATA_USER: async ({getters, commit}, payload) => {
    try {
      const response = await apiRequest({
        url: `/user/`,
        method: 'PATCH',
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
        data: payload
      });
      const res = response.data;
      commit('SET_DATA_USER', res);
      return response.status;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}