<template>
    <div class="popup_panel_body">
        <div class="popup_panel_create_area">
            <div class="panel_create_title">
                Name
            </div>
            <div class="popup_panel_create_body">
                <CustomInputComponent
                    ref="input_filter_name"
                    :typeInput="'text'"
                    :valueTextInput="''"
                    :placeholderInput="'Name of the filter'"
                    :changeFunction="changeUserFilterNameInput"/>
            </div>
        </div>
        <div class="choice_filters_wrapper">
            <ul class="choice_filters_list">
                <li class="choice_filter_item" v-for="filter in userCustomFiltersData">
                    <div class="choice_filter_item-title">
                        <div>
                            <img :src="filter.icon_path" alt="">
                        </div>
                        <div>
                            {{ filter.name }}
                        </div>
                    </div>
                    <div class="choice_filter_item-desc" v-if="filter.activeElements">
                        {{ filter.activeElements }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import CustomInputComponent from '@/components/Others/CustomInputComponent/CustomInputComponent.vue';
import { formatHQForPreview, formatSearchForPreview, formatterForPreview, unitedCommonFilters } from './functionsForFormatToView/functionsForFormatToView';

export default {
    name: "PanelChoiceFilters",
    components: {
        CustomInputComponent,
    },
    props: {
        changeUserFilterName: {
            type: Function,
            required: true,
        },
    },
    data () {
        return {
            userCustomFiltersData: [],
            userFilterName: '',
        }
    },
    methods: {
        changeUserFilterNameInput(value) {
            this.userFilterName = value;
            this.changeUserFilterName(value);
        },
    },
    mounted() {
        const activeProviders = formatterForPreview(this.$store.getters.ACTIVE_PROVIDERS);
        const activeGames = formatterForPreview(this.$store.getters.ACTIVE_GAMES);
        const activeTags = formatterForPreview(this.$store.getters.ACTIVE_TAGS);
        const activeHQFilter = formatHQForPreview(this.$store.getters.ACTIVE_HQ_FILTER);
        const activeSearchRequest = formatSearchForPreview(this.$store.getters.DATA_SEARCH_TEXT);
        const [assetFilter, commonFilters] = unitedCommonFilters(this.$store.getters.ACTIVE_FILTERS);
        this.userCustomFiltersData = [activeSearchRequest, ...assetFilter, activeProviders, activeGames, ...commonFilters, activeTags, activeHQFilter].filter(elem => elem ? Object.keys(elem).length : elem);
    },
}
</script>

<style scoped lang="scss">
.popup_panel_body {
    width: 100%;
    padding-right: 12px;
    gap: 24px;
    .popup_panel_create_area {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        .panel_create_title {
            font-size: 16px;
        }
        .popup_panel_create_body {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
    .choice_filters_wrapper {
        padding: 16px 5px 10px 0;
        background-color: #EBEFF5;
        border-radius: 10px;
        width: 100%;
        .choice_filters_list {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            padding: 0px 10px 0 16px;
            list-style: none;
            overflow-y: auto;
            scrollbar-gutter: stable;
            max-height: 40dvh;
            &::-webkit-scrollbar {
                width: 4px !important;
                background-color: #fff !important;
            }
            .choice_filter_item:not(:last-child) {
                border-bottom: 1px solid #D3D7DB;
            }
            .choice_filter_item {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 6px;
                padding-bottom: 10px;
                .choice_filter_item-title,
                .choice_filter_item-desc {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    text-align: start;
                }
                .choice_filter_item-title {
                    gap: 8px;
                    div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 700;
                        img {
                            height: 16px;
                            filter: brightness(0) saturate(100%);
                        }
                    }
                }
            }
        }
    }
}
</style>