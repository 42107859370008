<template>
    <div class="notification_filedata_component">
        <div class="notification_text" v-if="isSoftQuery && totalCount">
            <span>No exact results found. Showing similar matches instead</span>
        </div>
        <div class="notification_text" v-if="getTotalDataCount">
            <b>{{totalCount}}</b> images were found for your query
        </div>
        <NotFoundComponent v-if="!totalCount && !loadingTime && !isBadRequest"/>
    </div>
</template>

<script>
import NotFoundComponent from '@/components/Others/NotFoundComponent.vue';

export default {
    name: 'NotificationFileDataComponent',
    data () {
        return {
            totalCount: 0,
        }
    },
    components: {
        NotFoundComponent,
    },
    methods: {
    },
    props: {
        loadingTime: {
            type: Boolean,
            required: true,
        },
        isBadRequest: {
            type: Boolean,
            required: true,
        },

    },
    computed: {
        getTotalDataCount() {
            this.totalCount = this.$store.getters.TOTAL_DATA_COUNT;
            return this.totalCount;
        },
        isSoftQuery() {
            return this.$store.getters.IS_SOFT_QUERY;
        },
    },
}
</script>

<style scoped lang="scss">
    .notification_filedata_component {
        width: 100%;
        min-height: 20px;
        .notification_text {
            text-align: left;
            font-size: 16px;
            span {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 1;
            }
        }
    }
</style>
