import constants, {ICON_PATH} from "@/constants/constants";

import store from "@/store/index";
import { upperFirstLetterFunc } from "@/utils/formatingText/formatingText";

export const unitedCommonFilters = function(filters) {
    const filterArr = filters.reduce((formatElem, filter) => {
        const mainParentFilter = store.getters.PARENT_FILTERS_BY_PARENT_ID(filter.parent_id);
        if(!formatElem[mainParentFilter.name]) {
            formatElem[mainParentFilter.name] = { 
                name: mainParentFilter.name,
                subfilters: {},
                icon_path: mainParentFilter.icon_path,
            };
        }
        if(!formatElem[mainParentFilter.name]['subfilters'][filter.name]) {
            formatElem[mainParentFilter.name]['subfilters'][filter.name] = {
                activeElementName: filter.activeElementName,
                subfilter_name: mainParentFilter.children_filter ? filter.name : null,
            }
        } else {
            formatElem[mainParentFilter.name]['subfilters'][filter.name].activeElementName = `${formatElem[mainParentFilter.name]['subfilters'][filter.name].activeElementName}, ${filter.activeElementName}`;
        }
        return formatElem
    }, {});
    return pickFilterToList(filterArr);
};

const pickFilterToList = function (filtersArr) {
    const filtersResult = [];
    const assetResult = [];
    for(let i in filtersArr) {
        let word = '';
        for(let j in filtersArr[i]['subfilters']) {
            let text = '';
            if(filtersArr[i]['subfilters'][j].subfilter_name) {
                text = upperFirstLetterFunc(filtersArr[i]['subfilters'][j].subfilter_name, /(?:^|_)(\w)/g,/_/g) + ' - ' + filtersArr[i]['subfilters'][j].activeElementName;
            } else {
                text = filtersArr[i]['subfilters'][j].activeElementName;
            }
            word = word ? word + ', ' + text : text;
        }
        filtersArr[i].activeElements = word;
        if(filtersArr[i]['subfilters'][constants.FILTER_TYPE_ASSET]) {
            assetResult.push(filtersArr[i])
        } else {
            filtersResult.push(filtersArr[i])
        }
        delete filtersArr[i]['subfilters'];
    }
    return [assetResult, filtersResult];
};

export const formatterForPreview = function (elements) {
  const elemntsForView = elements.reduce((formatElem, {name, activeElementName, icon_path}) => {
      if(!formatElem.name) {
          formatElem.name = upperFirstLetterFunc(name, /(?:^|_)(\w)/g,/_/g);
          formatElem.icon_path = icon_path ? icon_path : ICON_PATH[name];
      }
      formatElem.activeElements = formatElem.activeElements ? `${formatElem.activeElements}, ${activeElementName}` : `${activeElementName}`;
      return formatElem;
  }, {});
  return elemntsForView;
};
export const formatHQForPreview = function (element) {
  if(!element.isActive) return;
  const arrName = element.name.split('_');
  const result = {
      name: `${arrName[0].toUpperCase()} ${arrName[1]}`,
      icon_path: ICON_PATH[element.name],
      activeElements: null,
  }
  return result;
};

export const formatSearchForPreview = function (element) {
  if(!element) return;
  return {
      name: 'Search',
      icon_path: ICON_PATH[constants.SEARCH_REQUEST],
      activeElements: `“${element}”`,
  }
};