<template>
    <PopupContainerActivate
        :functionClosePopUp="closePoUp"
        :functionAction="clearAllCollect"
        :popUpTitle="'Clear your collection?'"
        :popUpDescription="'This will remove all images from the collection'"
        :popUpButtonCancel="'Cancel'"
        :popUpButtonAccept="'Yes, clear'"
        :iconImage="IconClear"
        :styleTypeButton="0"
    />
</template>

<script>
import PopupContainerActivate from '../PopupContainerActivate/PopupContainerActivate.vue';
import IconClear from '@/images/svg/icon_clear.svg';

export default {
    name: "ClearAllPrecollectPopup",
    data() {
        return {
            IconClear,
        }
    },
    components: {
        PopupContainerActivate,
    },
    methods: {
    },
    props: {
        closePoUp: {
            type: Function,
            required: true,
        },
        clearAllCollect: {
            type: Function,
            required: true,
        }
    },
}
</script>