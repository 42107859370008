import apiRequest from '@/utils/apiRequest/apiRequest';
import constants from '@/constants/constants';

const state = {
  isViewCreateUserFilterPopup: false,
  dataUserFilters: [],
  canViewSmartFilters: false,
};

const getters = {
  DATA_USER_FILTERS: state => {
    return state.dataUserFilters;
  },
  IS_CREATE_USER_FILTER_POPUP_OPEN: state => {
    return state.isViewCreateUserFilterPopup;
  },
  CAN_VIEW_SMART_FILTERS: state => {
    return state.canViewSmartFilters;
  },
};

const mutations = {
  CHANGE_VIEW_USER_FILTER_POPUP: (state, payload) => {
    state.isViewCreateUserFilterPopup = payload.isOpen;
  },
  SET_DATA_USER_FILTERS: (state, payload) => {
    state.dataUserFilters = payload;
  },
  UPDATE_DATA_USER_FILTERS: (state, payload) => {
    state.dataUserFilters.push(payload);
  },
  DELETE_USER_FILTERS_FROM_DATA: (state, payload) => {
    state.dataUserFilters = state.dataUserFilters.filter(smart_filter => {
        return smart_filter.id !== payload;
    });
  },
  CHANGE_STATE_VIEW: (state, payload) => {
    state.canViewSmartFilters = true;
  },
  RESET_DATA_USER_FILTERS: (state, payload) => {
    state.dataUserFilters = [];
  },
};

const actions = {
  GET_DATA_USER_FILTERS: async ({getters, commit}, payload) => {
    try {
      const response = await apiRequest({
        url: `/smart_filters/user?user_id=${getters.DATA_USER.id}`,
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      const res = response.data;
      commit('SET_DATA_USER_FILTERS', res);
      commit('CHANGE_STATE_VIEW');
    } catch (err) {
      commit('CHANGE_STATE_VIEW');
      if(err.response.data.detail) {
        console.error(`${err.name}: ${err.response.data.detail}`);
      } else {
        console.error(`${err.name}: ${err.message}`);
      }
    }
  },
  CREATE_CUSTOM_FILTER: async ({getters, commit}, payload) => {
    const dataRequest = {
      name: payload,
      items: getters.ACTIVE_FILTERS.map(({id}) => id),
      asset_type_id: getters.ACTIVE_MAIN_ASSET.id,
      games: getters.ACTIVE_GAMES.map(({id}) => id),
      providers: getters.ACTIVE_PROVIDERS.map(({id}) => id),
      tags: getters.ACTIVE_TAGS.map(({id}) => id),
      search_string: getters.DATA_SEARCH_TEXT,
      is_hq: getters.ACTIVE_HQ_FILTER.isActive,
    }
    try {
      const response = await apiRequest({
        url: '/smart_filters/user',
        method: 'POST',
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "accept": "application/json",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
        data: JSON.stringify(dataRequest),
      });
      const res = response.data;
      commit('UPDATE_DATA_USER_FILTERS', res);
      return response;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
      return err.response || err;
    }
  },
  DELETE_CUSTOM_FILTER: async ({getters, commit, dispatch}, smartFilterID) => {
    try {
      const response = await apiRequest({
        url: `/smart_filters/user?smart_filter_id=${smartFilterID}`,
        method: 'DELETE',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      const res = response.data;
      const { IS_SUCCESS, STATUS_CODE } = constants;
      if(res.status === IS_SUCCESS && response.status === STATUS_CODE.OK) {
        commit('DELETE_USER_FILTERS_FROM_DATA', smartFilterID);
      }
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}