<template>
    <div class="main_filter_panel_component">
        <div class="second_head_panel">
            <SmartFiltersComponent v-if="!getActiveFilters.length"/>
            <ActiveFiltersComponent 
                :getActiveFilters="activeFilters"
                v-else/>
            <SortComponent/>
        </div>
        <div class="third_head_panel" >
            <NotificationFileDataComponent v-if="(activeFilters.length || getActiveSearch)"
                :loadingTime="loadingTime"
                :isBadRequest="isBadRequest"/>
        </div>
    </div>
</template>

<script>
import SmartFiltersComponent from './SmartFilters/SmartFiltersComponent/SmartFiltersComponent.vue';
import ActiveFiltersComponent from './ActiveFiltersComponent/ActiveFiltersComponent.vue';
import SortComponent from './SortComponent/SortComponent.vue';
import NotificationFileDataComponent from './NotificationFileDataComponent/NotificationFileDataComponent.vue';
import { encodeElementForURL } from '@/utils/en_decodeElementForURL/en_decodeElementForURL.js';
import constants from '@/constants/constants';

export default {
    name: 'MainFilterPanelComponent',
    data () {
        return {
            activeFilters: [],
            activeProviders: [],
            activeGames: [],
            activeCommonFilters: [],
            activeTags: [],
            activeSearch: '',
            activeMainAssets: null,
            activeHQFilter: {},
        }
    },
    components: {
        SmartFiltersComponent,
        ActiveFiltersComponent,
        SortComponent,
        NotificationFileDataComponent,
    },
    methods: {
        isHaveSaveUrl() {
            const queryPath = JSON.parse(localStorage.getItem('FILTER_PARAMS_ACTIVE'));
            return queryPath;
        },
        updateParametersURL() {
            const currentQuery = { ...this.$route.query };

            if(!currentQuery.assets) {
                const saveAuthQuery = this.isHaveSaveUrl();
                if(!saveAuthQuery?.assets) {
                    if(this.activeMainAssets?.name && this.activeMainAssets?.id) {
                        currentQuery.assets = encodeElementForURL(this.activeMainAssets, constants.ASSETS);
                    }
                    const searchText = this.$store.getters.DATA_SEARCH_TEXT;
                    if(!currentQuery.search_request && searchText) {
                        currentQuery.search_request = encodeElementForURL(searchText, constants.SEARCH_REQUEST);
                    }
                    const activeProviderURL = this.activeProviders.map(el => {
                        return encodeElementForURL(el, constants.PROVIDERS);
                    });
                    const activeGamesURL = this.activeGames.map(el => {
                        return encodeElementForURL(el, constants.GAMES);
                    });
                    const activeCommonFiltersURL = this.activeCommonFilters.map(el => {
                        return encodeElementForURL(el, constants.FILTERS);
                    });
                    const activeTagsURL = this.activeTags.map(el => {
                        return encodeElementForURL(el, constants.TAGS);
                    });
                    const activeHQ = this.$store.getters.DATA_HQ_FILTER ? 1 : undefined;

                    currentQuery.hq_only = activeHQ;
                    currentQuery.providers = activeProviderURL;
                    currentQuery.games = activeGamesURL;
                    currentQuery.filters = activeCommonFiltersURL;
                    currentQuery.tags = activeTagsURL;

                    if(currentQuery.assets) {
                        localStorage.setItem('FILTER_PARAMS_ACTIVE', JSON.stringify(currentQuery));
                    }
                }
                this.$router.push({query: !saveAuthQuery ? currentQuery : saveAuthQuery});
            }
        }
    },
    props: {
        loadingTime: {
            type: Boolean,
            required: true,
        },
        isBadRequest: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        getActiveFilters() {
            this.activeProviders = this.$store.getters.ACTIVE_PROVIDERS;
            this.activeGames = this.$store.getters.ACTIVE_GAMES;
            this.activeCommonFilters = this.$store.getters.ACTIVE_FILTERS;
            this.activeTags = this.$store.getters.ACTIVE_TAGS;
            this.activeMainAssets = this.$store.getters.ACTIVE_MAIN_ASSET;
            this.activeHQFilter = this.$store.getters.ACTIVE_HQ_FILTER;
            this.activeFilters = [...this.activeProviders, ...this.activeGames, ...this.activeCommonFilters, ...this.activeTags];

            if(this.activeHQFilter.isActive) {
                this.activeFilters.push(this.activeHQFilter)
            }

            return this.activeFilters;
        },
        getActiveSearch() {
            this.activeSearch = this.$store.getters.DATA_SEARCH_TEXT;
            return this.activeSearch.length;
        },
    },
    watch: {
        activeFilters() {
            this.updateParametersURL();
        },
    }
}
</script>

<style lang="scss">
    .main_filter_panel_component {
        width: 100%;
        min-width: 350px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        padding-left: 20px;
        padding-right: 10px;
        .second_head_panel,
        .third_head_panel {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .second_head_panel {
            min-height: 70px;
            gap: 4px;
            .active_filters_component,
            .smart_filters_component {
                overflow: hidden;
                overflow-y: auto;
                max-height: 70px;
            }
        }
        .third_head_panel {
            min-height: 40px;
            .not_found_container {
                margin-top: 10px;
                min-width: 160px;
            }
        }
    }
</style>