<template>
    <li class="collection_item">
        <CustomCheckboxComponent
          :checkboxInfo="collectionItem"
          :checkboxChangeFunction="updateCollectionListForUpdate"
          :stateCheckbox="!!collectionItem.isChecked"
          :isDisabled="false"/>
    </li>
</template>

<script>
import CustomCheckboxComponent from '@/components/Others/CustomCheckboxComponent/CustomCheckboxComponent.vue';

export default {
    name: "CollectionItemComponent",
    components: {
        CustomCheckboxComponent,
    },
    props: {
        collectionItem: {
            type: Object,
            required: true,
        },
        disableErrorMessage: {
            type: Function,
            required: true,
        },
    },
    methods: {
        updateCollectionListForUpdate(event) {
            this.disableErrorMessage();
            if(event.target.checked) {
                this.collectionItem.isChecked = true;
                this.$store.commit('ADD_COLLECTION_TO_LIST_FOR_UPDATE', [this.collectionItem, false]);
            } else {
                this.collectionItem.isChecked = false;
                this.$store.commit('REMOVE_COLLECTION_FROM_LIST_FOR_UPDATE', this.collectionItem.id);
            }
        }
    },
}
</script>

<style lang="scss">
.collection_item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    .custom_checkbox_container {
        justify-content: center;
        .custom_checkbox {
            height: 40px;
            padding-left: 16px;
            padding-right: 16px;
            background-color: #EBEFF5;
            border: 1px solid #EBEFF5;
            border-radius: 10px;
            box-sizing: border-box;
            .custom_checkmark {
                right: 16px;
                top: 12px;
                left: auto;
                border: 2px solid #757575;
            }
            .custom_checkbox_title {
                font-size: 16px;
            }
            &:hover {
                border: 1px solid #0085FF;
            }
        }
    }
    .disable_checkbox{
        .custom_checkbox {
            &:hover {
                border: 1px solid transparent;
            }
        }
    }
}
</style>
