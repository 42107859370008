<template>
    <div class="selected_image_container" >
        <SelectedImageView
          :openFullSizeImage="openFullSizeImage"
          :getImageForAside="getImageForAside"/>
        <SelectedImageDescription v-show="getImageForAside?.id"
          :addButtonAction="addButtonAction"
          :imageData="getImageForAside"/>
    </div>
    <FullSizePopupComponent 
        v-if="isFullPopUp"
        :viewElement="getImageForAside"
        :functionClose="openFullSizeImage"/>
</template>

<script>
import FullSizePopupComponent from '@/components/Others/FullSizePopupComponent/FullSizePopupComponent.vue';
import SelectedImageView from './SelectedImageView/SelectedImageView.vue';
import SelectedImageDescription from './SelectedImageDescription/SelectedImageDescription.vue';

export default {
    name: "SelectedImageContainer",
    components: {
        FullSizePopupComponent,
        SelectedImageDescription,
        SelectedImageView,
    },
    props: {
        getImageForAside: {
            type: [Object, null],
            required: true,
        },
        addButtonAction: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            isFullPopUp: false,
        };
    },
    methods: {
        openFullSizeImage() {
            if(!this.getImageForAside?.image_path) return;
            this.isFullPopUp = !this.isFullPopUp;
        },
    },
    // computed: {
    //   getAsideImage() { // должен стать универсальным
    //     this.imageData = this.$store.getters.ASIDE_IMAGE_DATA;
    //     const activeMainAsset = this.$store.getters.ACTIVE_MAIN_ASSET;
    //     /* if(activeMainAsset && activeMainAsset?.name.toLowerCase() !== constants.MAIN_ASSET_DEFAULT ) {  //заглушка для того чтобы убрать файлы другого ассета
    //         // this.imageData = null;
    //     //     setTimeout(() => {
    //     //         // this.notFileImage = true;
    //     //         // this.isLoadingImage = false;
    //     //     }, 200)
    //     } */
    //     return this.imageData;
    //   }
    // },
}
</script>

<style lang="scss">
    .selected_image_container {
        border-radius: 10px;
        background: #EBEFF5;
        position: relative;
        height: 100%;
        //min-height: 320px;
        display: flex;
        flex-direction: column;
        .update_description_container {
            flex-shrink: 1;
            min-height: 217px;
        }
    }
</style>