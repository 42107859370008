<template>
    <div class="selected_image_container_view">
        <div :class="`selected_image_container_item ${!fullPreview ? 'select_disable' : ''}`" v-show="!isLoadingImage" @click="openPopupFullElement" title="Open full size">
            <img
                class="selected_preview_file"
                ref="preview_img"
                :src="fullPreview"
                :onLoad="imageLoadStop"
                :onError="imageLoadError"
                @dragstart="setDropPreview"
                draggable="true"
                v-if="fullPreview"
                alt="View Element">
            <NotFilePreviewComponent :isDraggable="false" v-if="!fullPreview"/>
        </div>
        <SizeInfoComponent :imageSize="imageSize" v-if="!isLoadingImage && fullPreview"/>
        <LoaderComponent v-if="isLoadingImage"/>
    </div>
</template>

<script>
import SizeInfoComponent from './SizeInfoComponent/SizeInfoComponent.vue';
import LoaderComponent from '@/components/Others/LoaderComponent.vue';
import getSizePercent from '@/utils/getSizePercent/getSizePercent';
import constants from '@/constants/constants';
import NotFilePreviewComponent from '@/components/Others/NotFilePreviewComponent/NotFilePreviewComponent.vue';

export default {
    name: "SelectedImageView",
    components: {
        SizeInfoComponent,
        LoaderComponent,
        NotFilePreviewComponent,
    },
    props: {
        openFullSizeImage: {
            type: Function,
            required: true,
        },
        getImageForAside: {
            type: [Object, null],
            required: true,
        },
    },
    data() {
        return {
            isLoadingImage: false,
            fullPreview: "" ,
            imageSize: null,
        };
    },
    methods: {
        imageLoadStop() {
            this.isLoadingImage = false;
            setTimeout(() => {
                const image = this.$refs.preview_img;
                this.resizeObserver = new ResizeObserver(() => {
                    this.updateImageSize();
                });
                if(image) {
                    this.resizeObserver.observe(image);
                } else {
                    this.resizeObserver.disconnect();
                }
            },0);
        },
        imageLoadError(e) {
            this.isLoadingImage = false;
            this.fullPreview = '';
        },
        setDropPreview(e) {
            const img = document.createElement('img');
            img.src = this.getImageForAside.image_preview_path;
            e.dataTransfer.setDragImage(img, 100, 100);
        },
        getFullPreview(id) {
            this.isLoadingImage = true;
            this.fullPreview = '';
            this.$store.dispatch('DEBOUNCED_GET_FULL_IMAGE', id).then((res) => {
                const { STATUS_CODE } = constants;
                const { status } = res;
                if(status === STATUS_CODE.OK) {
                    this.fullPreview = res.data;
                } else if(status === STATUS_CODE.BAD_LOG_IN || status === STATUS_CODE.BAD_REQUEST || status === STATUS_CODE.CONFLICT) {
                    this.isLoadingImage = false;
                }
                this.updateImageSize();
            }).catch((err) => {
                this.isLoadingImage = false;
            });
        },
        updateImageSize() {
            const preview = this.$refs.preview_img;
            if(preview) {
                const {width, naturalWidth, height, naturalHeight} = preview;
                this.imageSize = getSizePercent(width, naturalWidth, height, naturalHeight);
            }
        },
        openPopupFullElement() {
            if(this.fullPreview) {
                this.openFullSizeImage();
            }
        },
    },
    watch: {
        getImageForAside(newValue, oldValue) {
            const previewData = this.$store.getters.IMAGE_LINK;
            if(oldValue?.id !== newValue?.id && previewData?.id !== newValue?.id) {
                this.getFullPreview(newValue?.id)
            } else if(oldValue.isEmpty) {
                this.fullPreview = previewData.link;
            }
        },
    },
    mounted() {
        if(this.getImageForAside?.id) {
            const previewData = this.$store.getters.IMAGE_LINK;
            if(this.getImageForAside.id === previewData?.id) {
                this.fullPreview = previewData.link;
            } else if(this.$route.name === constants.PATH_NAME.SEARCH) {
                this.getFullPreview(this.getImageForAside.id);
            }
        }
    },
    unmounted() {
        if(this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
}
</script>

<style lang="scss">
.selected_image_container_view {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 320px;
    border-radius: 10px;
    flex-grow: 1;
    flex-shrink: 452;
    background-color: #e6e6e6;
    background-image: -webkit-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF), -webkit-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF);
    background-image: -moz-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF), -moz-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF);
    background-image: -o-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF), -o-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF);
    background-image: -ms-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF), -ms-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF);
    background-image: linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF), linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF);
    -webkit-background-size: 20px 20px;
    -moz-background-size: 20px 20px;
    background-size: 20px 20px;
    background-position: 0.5px 0.5px, 10px 10px;
    .selected_image_container_item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        height: 100%;
        padding: 5px;
        cursor: pointer;
        .selected_preview_file {
            display: block;
            max-width: 100%;
            max-height: 100%;
            pointer-events: auto;
            object-fit: contain;
        }
    }
    .select_disable {
        cursor: default;
    }
    .loader_container {
        svg path,
        svg rect{
            fill: #bbbbbb;
        }
    }
}
</style>
