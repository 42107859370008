<template>
  <div class="filters_panel_type">
    <div class="filters_panel_type-header" @click="openFilterType">
      <div class="filters_panel_type-title">
        <div class="filters_panel_type-enable" v-if="isFilterTypeEnable"></div>
        <div>
          <img src="@/images/svg/icon_other_default.svg" alt="" v-if="!filterType.icon_path">
          <img :src="filterType.icon_path" alt="" v-else>
        </div> 
        <div>
          {{ filterType.name }}
        </div>
        <FilterNotificationComponent :filterDescription="filterType.description"/>
      </div>
      <RotateArrowIcon
          :isRotateIcon="isFilterTypeOpen"
          :styleIcon="0"/>
    </div>
    <CommonFilterElementsComponent
      :filterType="filterType"
      :isFilterTypeOpen="isFilterTypeOpen"
    />
  </div>
</template>

<script>
import FilterNotificationComponent from './FilterNotificationComponent/FilterNotificationComponent.vue';
import CommonFilterElementsComponent from './CommonFilterElementsComponent/CommonFilterElementsComponent.vue';
import RotateArrowIcon from '@/components/Others/RotateArrowIcon/RotateArrowIcon.vue';

export default {
  name: 'FilterTypeComponent',
  data() {
    return {
      isFilterTypeOpen: false,
      arrActiveFilters: [],
    }
  },
  components: {
    CommonFilterElementsComponent,
    FilterNotificationComponent,
    RotateArrowIcon,
  },
  props: {
    filterType: {
        type: Object,
        required: true,
    },
  },
  methods: {
    openFilterType() {
      this.isFilterTypeOpen = !this.isFilterTypeOpen;
    },
  },
  mounted() {
    if(this.filterType.isProvider) {
      this.openFilterType();
    }
  },
  computed: {
    isFilterTypeEnable() {
      if(!Object.keys(this.filterType).length) return;
      if(this.filterType.isProvider) {
        const active_array = this.$store.getters.ACTIVE_PROVIDERS;
        this.arrActiveFilters = [...active_array];
      } else if(this.filterType.isGame) {
        const active_array = this.$store.getters.ACTIVE_GAMES;
        this.arrActiveFilters = [...active_array];
      } else if(this.filterType.isTag) {
        const active_array = this.$store.getters.ACTIVE_TAGS;
        this.arrActiveFilters = [...active_array];
      } else {
        return !!this.$store.getters.ACTIVE_FILTERS_BY_PARENT_ID(this.filterType.id);
      }
      return this.arrActiveFilters.length;
    },
  }
};
</script>

<style scoped lang="scss">
  .filters_panel_type {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-content: center;
      align-items: flex-start;
      .filters_panel_type-header {
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        width: 100%;
        cursor: pointer;
        &:hover .filters_panel_type-title {
            color: #0085FF;
        }
        .filters_panel_type-title {
            position: relative;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            gap: 8px;
            transition: all .3s ease-out;
            padding-left: 9px;
            div {
              display: flex;
              justify-content: center;
            }
            img {
              width: 16px;
              height: 16px;
            }
            .filters_panel_type-enable {
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #FFCC29;
              left: 1px;
            }
        }
      }
    }
</style>