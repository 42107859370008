<template>
    <section class="images_view_component" draggable="false">
      <div class="images_view_component_body"
        v-if="dataImages.length !== 0">
        <ul class="images_view_component_list" 
          ref="data_component_list_body"
          v-on:scroll="scrollElements">
          <ImageComponent
            v-for="(imageData, index) in dataImages"
            :imageData="imageData"
            :key="index"
            :index="index"
            :addButtonAction="addButtonAction"
            :activeImage="activeImage"/>
          <div class="loading_wrapper">
            <div v-if="loadingTime && !isCollectionView">Loading...</div>
          </div>
        </ul>
        <div class="view_bottom_elements">
          <ButtonNextPageComponent
            :actionFunction="nextPage"
            :disabledState="isMaxPage"
            v-if="dataImages.length >= maxPageSize"/>
          <div class="view_page_counter">
            Page <span class="view_current_page">{{ currentPage }}</span> of {{ lastPage }}
          </div>
        </div>
      </div>
        <LoaderComponent v-else-if="loadingTime" />
        <BadRequesComponent v-else-if="isBadRequest"/>
        <WorkInProgress v-else/>
    </section>
</template>

<script>
import ImageComponent from './ImageComponent/ImageComponent.vue';
import LoaderComponent from '@/components/Others/LoaderComponent.vue';
import BadRequesComponent from '@/components/Others/BadRequesComponent.vue';
import WorkInProgress from '@/components/Others/WorkInProgress.vue';
import ButtonNextPageComponent from '@/components/Others/ButtonNextPageComponent/ButtonNextPageComponent.vue';
import constants from '@/constants/constants';

export default {
  name: 'ImagesViewComponent',
  components: {
    ImageComponent,
    ButtonNextPageComponent,
    LoaderComponent,
    BadRequesComponent,
    WorkInProgress,
  },
  data() {
      return {
          maxPageSize: constants.SIZE_PAGE,
          previousActiveElement: {},
          isCollectionView: this.$route.params.collection_name,
      }
  },
  props: {
    dataImages: {
        type: Array,
        required: true,
    },
    useAsideData: {
      type: Function,
      required: true,
    },
    isDataHaveFile: {
      type: Function,
      required: true,
    },
    nextPageGetData: {
      type: Function,
      required: true,
    },
    isMaxPage: {
      type: Boolean,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    addButtonAction: {
        type: Function,
        required: true,
    },
    loadingTime: {
        type: Boolean,
        required: true,
    },
    isBadRequest: {
        type: Boolean,
        required: true,
    },
  },
  methods: {
    activeImage(selectedElement) {
      const prevActiveElement = this.useAsideData();
      this.previousActiveElement.selected = false;
      this.previousActiveElement = selectedElement;
      if(prevActiveElement?.id !== selectedElement.id) {
        if(Object.values(prevActiveElement).length) {
          prevActiveElement.selected = false;
        }
        this.addElementToAside(selectedElement);
      } else {
        selectedElement.selected = true;
      }
    },
    addElementToAside(element) {
      element.selected = true;
      this.useAsideData(element);
    },
    updateActiveElement(elementUpdate) {
      const prevActiveElement = this.useAsideData();
      if(Object.values(prevActiveElement).length) {
        const prevElement = this.isDataHaveFile(prevActiveElement.id);
        if(!prevElement) {
          this.activeImage(elementUpdate);
        } else {
          this.addElementToAside(prevElement);
        }
      } else {
        this.activeImage(elementUpdate);
      }
    },
    nextPage() {
      const element = this.$refs.data_component_list_body;
      const end = element.scrollHeight;
      element.scrollTo({
        top: end,
        left: 0,
        behavior: 'instant'
      });
      this.nextPageGetData();
    },
    resetScroll() {
      const element = this.$refs.data_component_list_body;
      if(!element) return;
      element.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    },
    scrollElements(element) {
      const positionScroll = element.target.scrollTop;
      const endScrolling = element.target.scrollHeight - element.target.clientHeight;
      if(positionScroll >= endScrolling) {
        this.nextPage();
      }
    },
  },
  watch: {
    dataImages(newData, _) {
      if(newData.length) {
        this.updateActiveElement(newData[0]);
      } else {
        this.updateActiveElement({
          isEmpty: true,
        });
      }
    },
    currentPage(newPage, _ ) {
      if(newPage === constants.DEFAULT_CURRENT_PAGE_NUM) {
        this.resetScroll();
      }
    },
  },
  mounted() {
    if(this.dataImages.length) {
      this.updateActiveElement(this.dataImages[0]);
    }
  },
};
</script>

<style scoped lang="scss">
  .images_view_component {
    //flex-shrink: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100%;
    max-height: calc(100vh - 246px);
    .images_view_component_body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
      height: 100%;
      //max-height: calc(100vh - 260px);
      .images_view_component_list {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px 10px 30px 20px;
        list-style: none;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-gutter: stable;
        .loading_wrapper {
          width: 100%;
          height: 20px;
          div {
            width: 100%;
          }
        }
      }
      .view_bottom_elements {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        padding: 14px 0;
        .view_page_counter {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          right: 0px;
          .view_current_page {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #757575;
            border-radius: 10px;
            width: 40px;
            height: 40px;
            font-weight: 600;
            margin: 0 6px;
          }
        }
      }
    }
  }
</style>