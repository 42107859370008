<template>
    <li class="selected_item__common_info">
        <p v-html="getTagMatchesInSearches()"  class="search_match"></p>
    </li>
</template>

<script>
import constants from '@/constants/constants';

export default {
    name: "SelectedItemCommonInfo",
    data() {
        return {
        }
    },
    props: {
        additionalTags: {
            type: String,
            required: true,
        }
    },
    methods: {
        getTagMatchesInSearches() {
            let searchRequest = this.$store.getters.DATA_SEARCH_TEXT;
            if(!searchRequest || searchRequest.length < constants.MIN_MATCH_SYMBOL) return this.additionalTags;

            let str = this.additionalTags;
            searchRequest = searchRequest.replace(/[\s,]/g, m => (m == ',') ? '_' : '_ ').split('_');
            searchRequest.forEach(searchWord => {
                if(!searchWord.trim()) return;

                const regex = new RegExp(`${searchWord.trim()}`,"gi");
                str = str.replace(regex, `<span>$&</span>`);
            })

            return str;
        },
    },
}
</script>

<style lang="scss">
    .selected_item__common_info {
        width: 100%;
        padding: 6px 0;
        color: #757575;
        font-size: 15px;
        text-align: left;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        .search_match {
            span {
                color: #111111;
                font-weight: 700;
            }
        }
    }
</style>
