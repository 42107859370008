import constants, { ICON_PATH } from '@/constants/constants';

import apiRequest from '@/utils/apiRequest/apiRequest';
import convertArrayToObject from '@/utils/arrayToObject/arrayToObject';
import { restoreActiveElements } from '@/utils/restoreActiveElements/restoreActiveElements';

const state = {
  dataProviders: {
    name: 'Providers',    //требуется исправить на сервере
    description: 'Information about providers',
    icon_path: ICON_PATH[constants.PROVIDERS],
    fileList: [],
    povider_number: null,
    isProvider: true,
  },
  allDataProviders: [],
  allProvidersStore: {},
  activeProviders: [],
  inactiveDefaultProviders: [],
};

const getters = {
  DATA_PROVIDERS: state => {
    return state.dataProviders;
  },
  ALL_DATA_PROVIDERS: state => {
    return state.allDataProviders;
  },
  GET_STORE_PROVIDERS: state => {
    return state.allProvidersStore;
  },
  ACTIVE_PROVIDERS: state => {
    return state.activeProviders;
  },
  INACTIVE_DEFAULT_PROVIDERS: state => {
    return state.inactiveDefaultProviders;
  },
  PROVIDER_BY_ID: (state) => (providerId) => {
    return state.allProvidersStore[providerId];
  },
  ACTIVE_PROVIDERS_BY_ID: (state) => (id) => {
    return state.activeProviders.find(provider => provider.id === id);
  },
};

const mutations = {
  SET_DATA_PROVIDERS: (state, payload) => {
    state.dataProviders.fileList = payload.provider;
    state.dataProviders.povider_number = payload.povider_number;
  },
  SET_ALL_DATA_PROVIDERS: (state, payload) => {
    state.allDataProviders = payload;
  },
  SET_ALL_PROVIDERS_STORE: (state, payload) => {
    state.allProvidersStore = payload;
  },
  ADD_ACTIVE_PROVIDERS: (state, payload) => {
    state.activeProviders.push(payload);
  },
  ADD_ACTIVE_PROVIDERS_RESTORE: (state, payload) => {
    // console.log(state.dataProviders.providerList)
    restoreActiveElements(state.allProvidersStore, state.dataProviders.fileList, state.activeProviders, payload);
  },
  UPDATE_DEFAULT_DATA_PROVIDER: (state, payload) => {
    // console.log(state.dataProviders.providerList)
    const isElement = state.dataProviders.fileList.find(({id}) => id === payload.id);
    if(!isElement) {
      // console.log(state.dataProviders.providerList)
      state.dataProviders.fileList.push(payload);
    }
  },
  REMOVE_ACTIVE_PROVIDERS: (state, payload) => {
    state.activeProviders = state.activeProviders.filter(provider => {
        return  provider.value !== payload.value;
    });
  },
  REMOVE_ALL_ACTIVE_PROVIDERS: (state, payload) => {
    state.activeProviders = [];
  },
  RESET_DATA_PROVIDERS: (state, payload) => {
    // state.dataProviders = [];
    state.dataProviders.fileList = [];
    state.dataProviders.povider_number = [];
    state.allDataProviders = [];
    state.activeProviders = [];
    state.allProvidersStore = {};
    state.inactiveDefaultProviders = [];
  },
};

const actions = {
  GET_DATA_PROVIDERS: async ({getters, commit}, payload) => {
    try {
      commit('SET_IS_FILTERS_DATA', [constants.PROVIDERS, true]);
      const response = await apiRequest({
        url: '/provider/?get_all=false',
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      commit('SET_IS_FILTERS_DATA', [constants.PROVIDERS, false]);

      const res = response.data;
      commit('SET_DATA_PROVIDERS', res);
    } catch (err) {
      commit('SET_IS_FILTERS_DATA', [constants.PROVIDERS, false]);
      commit('SET_IS_BAD_REQUEST_FILTERS', [constants.PROVIDERS, true]);
      console.error(`${err.name}: ${err.message}`);
    }
  },
  GET_ALL_DATA_PROVIDERS: async ({getters, commit}, payload) => {
    try {
      const response = await apiRequest({
        url: '/provider/?get_all=true',
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      const res = response.data;
      commit('SET_ALL_DATA_PROVIDERS', res);

      if(!Object.keys(getters.GET_STORE_PROVIDERS).length) {
        commit('SET_ALL_PROVIDERS_STORE', convertArrayToObject(res));
      }

      return res;
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
}