<template>
    <li :class="isCheckedTag ? 'tag_item active' : 'tag_item'" @click="applyFilter">
        {{ filterSubtype.name }}
    </li>
</template>

<script>
import constants from '@/constants/constants';
import createObjectForStore from '@/utils/createObjectForStore/createObjectForStore.js';

export default {
  name: 'TagSubtypeComponent',
  data() {
    return {
        checkedFilter: false,
    }
  },
  components: {
  },
  props: {
    filterSubtype: {
        type: Object,
        required: true,
    },
    parentType: {
        type: Object,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
    // updateDefaultTags: {
    //     type: Function,
    //     required: false,
    // }
  },
  methods: {
    // checkedTag() {
    //     this.checkedFilter = !this.checkedFilter;
    //     if(this.updateDefaultTags) {
    //         this.updateDefaultTags(this.filterSubtype)
    //     }
    //     this.applyFilter(this.checkedFilter);
    // },
    applyFilter() {
        this.checkedFilter = !this.checkedFilter;
        const filter = createObjectForStore(this.parentType, this.filterSubtype);

        if(this.checkedFilter) {
            this.$store.commit('ADD_ACTIVE_TAG', filter);
            this.$store.commit('UPDATE_DEFAULT_DATA_TAG', this.filterSubtype);
            this.updateParametersURL(filter, constants.TAGS, true)
        } else {
            this.$store.commit('REMOVE_ACTIVE_TAG', filter);
            this.updateParametersURL(filter, constants.TAGS, false)
        }
        this.$store.dispatch('DEBOUNCED_GET_DATA', '');
    }
  },
  computed: {
    isCheckedTag() {
        const tag = this.$store.getters.ACTIVE_TAG_BY_ID(this.filterSubtype.id);
        this.checkedFilter = !!tag;
        return !!tag;
    },

  }
};
</script>

<style scoped lang="scss">
    .tag_item {
        border-radius: 32px;
        font-size: 14px;
        background: #EBEFF5;
        padding: 6px 18px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all .3s ease-out;
        cursor: pointer;
        &:hover {
            background: #FFCC29;
        }
        &.active {
            background: #FFCC29;
        }
    }
</style>