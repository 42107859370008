export default function sortByNameElement(arr) {
    return arr.sort((a, b) => {
        let firstCharA = a.name.toLowerCase();
        let firstCharB = b.name.toLowerCase();
        return firstCharA.localeCompare(firstCharB);
    });
}

export function sortDecrIncr(arr, isDecrise) {
    return arr.sort((a, b) => {
        let firstCharA = a.files.length;
        let firstCharB = b.files.length;
        return isDecrise ? firstCharA - firstCharB : firstCharB - firstCharA;
    });
}
